<p-card class="_100vh">
    <p-header class="title">{{title}}</p-header>
    <form  [@toggle]="toggle" [formGroup]="form" novalidate>
        <label for="yearName">{{PHSELECTYEAR}}</label>
        <div>
          <div>
            <p-dropdown [options]="years" appendTo="body" formControlName="yearName" placeholder="{{PHSELECTYEAR}}"
              optionLabel="name"></p-dropdown>
          </div>
          <div class="video-search">
            <span  dir="ltr" class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <p-autoComplete appendTo="body" ormControlName="titleName" placeholder="{{PHSEARCH}}" [suggestions]="results" [showClear]="true" [size]="50" (onClear)="ACclear()"  (onSelect)="ACSelect($event)" (completeMethod)="search($event)" ></p-autoComplete>
            </span>
          </div>
        </div>
    </form>
    <div id="section-gallery" scroll (scrollPosition)="scrollChanged($event)" [ngClass]="isVisible ? '' : 'content-vh-78'" >
        <div id="container">
            <div *ngFor="let video of videoArrays; index as i;">
                <kruh-vimeo-player [id]="video.vimeoid" width="320"></kruh-vimeo-player>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>

    </div>
</p-card>
