
<div *ngFor="let item of progaminteranlarray" class="preview">
      <p-carousel  *ngIf="item.imagesArray.length>0" [value]="item.imagesArray" styleClass="custom-carousel" [numVisible]="1" [numScroll]="1" [circular]="item.imagesArray.length > 1? true:false" [autoplayInterval]="5000" [responsiveOptions]="responsiveOptions">

        <ng-template let-img pTemplate="item">
            <div class="product-item">
                <div class="product-item-content">
                    <div class="p-mb-3">
                        <img src="{{img.code}}" class="product-image" title="{{item.title}}"/>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-carousel>
    <div *ngIf="item.tagline" class="center italic"><p> <span>"{{item.tagline}}"</span></p></div>
    <div class="center"><p><span>{{item.dateevent| date:'EEEE d MMM yyyy HH:mm':undefined:language}}</span></p></div>
    <div class="container-details">
        <div *ngIf="item.gid==6" class="filmdetailinfolinks">
            <div>
              <p><span>{{REGIE}}:</span>  {{item.regie}}</p>
            </div>
            <div *ngIf=" item.countryNames">
              <p><span>{{COUNTRY}}:</span> <ng-container *ngFor="let co of item.countryNames">
                {{co.name}}
            </ng-container> {{item.year}}</p>
            </div>
            <div>
              <p><span>{{LENGTH}}:</span>  {{item.length}} min.</p>
            </div>
            <div *ngIf="item.pricedata">
              <p><span>{{PRICE}}:</span> {{item.year < 2002? 'DM':''}} {{item.pricedata}} </p>
            </div>
            <div *ngIf="item.stars">
              <p><span>{{STARS}}:</span> {{item.stars}}</p>
            </div>
            <div><p><span>{{GENRE}}:</span>
              <ng-container *ngFor="let genres of item.genre">
                 {{genres.name}}
             </ng-container></p>
             </div>

          </div>
          <div *ngIf="item.gid!=6 && item.pricedata" class="filmdetailinfolinks">
            <div>
              <p><span>{{PRICE}}:</span> {{item.pricedata=='?'?PRICEFOLLOW:item.pricedata}}</p>
            </div>
          </div>
        <div *ngIf="item.gid==6" class="filmdetailinforechts">
          <div *ngIf="item.contentrating" class="fsk">
            <img src="/assets/img/fsk/{{item.contentrating}}.jpg" alt="fsk {{item.contentrating}}" title="fsk {{item.contentrating}}" />
          </div>
            <!-- <p>
              <a href="https://www.youtube.com/watch?v={{item.youtubeid}}"><span class="ffk-icon ffk-icon-trailer"></span>{{TRAILER}}</a>
            </p> -->
            <p-accordion expandIcon="ffk-icon ffk-icon-trailer" *ngIf="item.youtubeid">
              <p-accordionTab header="{{TRAILER}}" >
                <div *ngIf="item.youtubeid" [style.background-image]="item.ytimg" class="ytimg-background">
                  <youtube-player *ngIf="item.youtubeid"
                  [videoId]="item.youtubeid"
                  width="100%"
                  height="100%"
                  >
                  </youtube-player>
              </div>
             </p-accordionTab>
            </p-accordion>
          <p-accordion expandIcon="ffk-icon ffk-icon-share">
            <p-accordionTab header="{{SHARE}}" >
              <a href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Ffilmfestkruh.de%2Fde%2Fprogram%2Fdetails%2F{{item.id}}" rel="noopener" title="facebook" target="_blank">
                <span class="ffk-icon ffk-icon-facebook hidden-xs hidden-sm"></span>
                <span class="ffk-icon ffk-icon-facebook-gross hidden-md hidden-lg"></span></a>
              <a href="https://twitter.com/home?status=http%3A%2F%2Ffilmfestkruh.de%2Fde%2Fprogram%2Fdetails%2F{{item.id}}" rel="noopener" title="twitter" target="_blank"><span class="ffk-icon ffk-icon-twitter hidden-xs hidden-sm"></span><span class="ffk-icon ffk-icon-twitter-gross hidden-md hidden-lg"></span></a>
              <a href="https://plus.google.com/share?url=http%3A%2F%2Ffilmfestkruh.de%2Fde%2Fprogram%2Fdetails%2F{{item.id}}" rel="noopener" title="google" target="_blank"><span class="ffk-icon ffk-icon-google hidden-xs hidden-sm"></span><span class="ffk-icon ffk-icon-google-gross hidden-md hidden-lg"></span></a>
            </p-accordionTab>
          </p-accordion>
          <div *ngIf="item.awards">
            <p>{{item.awards}}</p>
          </div>
        </div>
    </div>
    <div [innerHTML]="synopsis" class="fk-synosis">
    </div>
    <!-- <div *ngIf="item.youtubeid" [style.background-image]="item.ytimg" class="ytimg-background">
        <youtube-player *ngIf="item.youtubeid"
        [videoId]="item.youtubeid"
        width="100%"
        height="100%"
        >
        </youtube-player>
    </div> -->
</div>

