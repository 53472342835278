import { AfterViewInit, Component, Input, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import Player from "@vimeo/player";


@Component({
  selector: 'kruh-vimeo-player',
  templateUrl: './vimeo-player.component.html',
  styleUrls: ['./vimeo-player.component.css']
})
export class VimeoPlayerComponent  implements AfterViewInit{
  @Input() id: number = 351671787;
  @Input() width!: string;
  @ViewChildren('player_container') playerContainer: any;
  private player!: Player;
 
  @ViewChild('player_container', { read: ViewContainerRef })
  playerContainer2!: ViewContainerRef;
  options = {};
  
 
  ngAfterViewInit() {
    this.options = {
      id: this.id,
      width:+this.width,
      color:'ff6400',
      playsinline:false,
      responsive:true,
      byline:false,
    };
    /* wait DOM be available */
    //this.playerContainer.changes.subscribe((item:any) => {
        if (this.playerContainer2.length==0) {
            /* DOM AVAILABLE */
            this.player = new Player(this.playerContainer.first.nativeElement,this.options);

            this.player.on('play', function() {
                // console.log('played the video!');
            });

            this.player.getVideoTitle().then(function(title) {
                // console.log('title:', title);

            });
        }
       //});
  }
}

// <a href="https://vimeo.com/351671787"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/351672327"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/351607755"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/351609040"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/351609388"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343795330"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343795960"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343796094"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343796184"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343795183"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343794327"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/222793593"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/177198982"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/177198731"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/177197687"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/177196532"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/177197110"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/177195668"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/177194882"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/177194421"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/177193712"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/177199833"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/177193000"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/177192463"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/177191889"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/175657984"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/47400873"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/102727275"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/102807637"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/103351861"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/103352890"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/103355497"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/103359487"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/71897637"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/71898043"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/71897205"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/71944517"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/71943148"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/72091348"><span class="video">@Resources.video.Watch</span></a>    
// <a href="https://vimeo.com/47823861"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/102727275"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/102807637"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/103351861"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/103352890"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/103355497"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/103359487"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343813120"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343812372"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343814213"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343813836"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343811857"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343812449"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343812511"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343812692"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343812746"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343812848"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343812979"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343813370"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343811192"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343812267"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343810906"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343811421"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343811772"><span class="video">@Resources.video.Watch</span></a>
// <a href="https://vimeo.com/343811949"><span class="video">@Resources.video.Watch</span></a>
// @*<a href="http://www.youtube.com/watch?v=bvQDAuSSHkM"><span class="video">@Resources.video.WatchYoutube</span></a>*@