import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule
  // , SwRegistrationOptions
} from '@angular/service-worker';
import { environment } from '../environments/environment';


import { HttpClient, HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PageNotFoundComponent } from './pages/page-not-found.component';
import { ErrorDialogComponent } from './components/error/error-dialog.component';
import { ToolbarComponent } from './components/toolbar.component';
import { CoreModule } from './core/core.module';
import { PrimeNGModule } from './shared/primeng.modules';
import { ErrordialogService } from './core/services/errordialog.service';
import { APP_BASE_HREF } from '@angular/common';
import { WindowRef } from './window-ref';

import { JwtHelperService } from './core/jwthelper.services';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { NgcCookieConsentModule,NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { MessageService} from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { MenuComponent } from './components/menu/items.component';
import { ErrorInterceptor } from './core/error.interceptor';
import { AppInsightsService } from './core/services/app-insights.service';
import { ApiTokenService } from './core/services/apitoken/api-token.service';

import { MonitoringErrorHandler } from './core/error.handler';
import { AppPreloadingStrategy } from './helper/app-preload-strategy';
import { LAZY_WIDGETS } from './Lazy/tokens';
import { lazyArrayToObj } from './Lazy/lazy-widgets';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModules } from './modules/material/material.module';
import { HeartBeatComponent } from './components/heartbeat/heart-beat.component';
import { SignalrService } from './core/services/signalR/SignalrService';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DialogService } from 'primeng/dynamicdialog';
import { LoadingInterceptorService } from './core/loading.interceptor.service';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { MSALGuardConfigFactory } from './core/auth/msal-guard-config-factory';
import { MSALInterceptorConfigFactory } from './core/auth/msal-interceptor-config-factory';
import { MSALInstanceFactory } from './core/auth/msal-instance-factory';
import { TextSelectionService } from './helper/selected-text';

const elem:any=document.getElementById("cookie-consent-content")!==null?document.getElementById("cookie-consent-content"):undefined;
const cookieConfig:NgcCookieConsentConfig={
  cookie: {
    domain: environment.domain
  },
  position: "bottom",
  theme: "classic",
  palette: {
  popup: {
  background: "#000000",
  text: "#ffffff",
      link: "#ffffff"
    },
    button: {
     background: "#f1d600",
      text: "#000000",
      border: "transparent"
    }
  },
  type: 'info',
  content: {
    message: "This website uses cookies to ensure you get the best experience on our website.",
    dismiss: "Got it!",
    deny: "Refuse cookies",
    link: "Learn more",
    href: `https://${environment.domain}/info/policy`,
    policy: 'Cookie Policy'

  },
  container: elem
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ErrorDialogComponent,
    ToolbarComponent,
    HeartBeatComponent,
    DashboardComponent,
    MenuComponent
  ],
  imports: [
    CoreModule,
    HttpClientModule,HttpClientJsonpModule ,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FlexLayoutModule,
    PrimeNGModule,
    MaterialModules,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register(environment.serviceWorkerScript, { enabled: environment.production, scope:"/"
    }),
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  exports: [FontAwesomeModule],
  providers: [
        {
     provide: HTTP_INTERCEPTORS,
     useClass: MsalInterceptor,
     multi: true
   },

   {
     provide: MSAL_INSTANCE,
     useFactory: MSALInstanceFactory
   },
   {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
  },

   {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory
  },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
   {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptorService,
    multi: true
  },
    AppPreloadingStrategy,
    ErrordialogService,
    DialogService,
    TextSelectionService,
    ApiTokenService,
    JwtHelperService,
    AppInsightsService, {
      provide: ErrorHandler,
      useClass: MonitoringErrorHandler
      },
    WindowRef,
    {provide: APP_BASE_HREF, useValue: '/' },
    {provide: LOCALE_ID, useValue: 'de-De' },
    MessageService,
    CookieService,
     {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LAZY_WIDGETS, useFactory: lazyArrayToObj },
    // {
    //   provide: SwRegistrationOptions,
    //   useFactory: () => ({registrationStrategy: 'registerImmediately',scope:'/',enabled: environment.production}),
    // },
    SignalrService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (signalrService: SignalrService) => () => signalrService.initiateSignalrConnection(),
    //   deps: [SignalrService],
    //   multi: true,
    // }
  ],
  bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }
