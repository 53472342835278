import { registerLocaleData } from '@angular/common';
import { Component,  OnInit } from '@angular/core';
import localeNl from '@angular/common/locales/nl';
import localeDE from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import { IPrograminternal } from 'src/app/core/model/programinternal.interface';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {DomSanitizer} from '@angular/platform-browser';
@Component({
  selector: 'kruh-program-details-dialog',
  templateUrl: './program-details-dialog.component.html',
  styleUrls: ['./program-details-dialog.component.css']
})
export class ProgramDetailsDialogComponent implements OnInit {
  progaminteranlarray: IPrograminternal[]=[];
  responsiveOptions = [
    {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
    },
    {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
    },
    {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
    }
];
  PHSELECTYEAR: any;
  TRAILER: any;
  PRICE: any;
  AWARDS: any;
  TAGLINE: any;
  STARS: any;
  GENRE: any;
  LMOVIE: any;
  LYEAR: any;
  YEAR: any;
  DATEEVENT: any;
  LENGTH: any;
  COUNTRY: any;
  REGIE: any;
  SHARE: any;
  LTITLE: any;
  language: string = "en";
  synopsis: any;
  PRICEFOLLOW: string = "Price not yet known";
  
  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private _translate: TranslateService,
    private domSanitizer:DomSanitizer
  ) {
    registerLocaleData(localeNl, 'nl');
    registerLocaleData(localeDE, 'de');
    registerLocaleData(localeFr, 'fr');
    this.language = this._translate.currentLang;
   }

  ngOnInit(): void {

    
    this.GetLanguageData();
    this._translate.onLangChange.subscribe((event:TranslationChangeEvent) => {
      const app = event.translations['APP'];
      const program = event.translations['PROGRAM'];
      this.setvalues(app, program);
    });
   
    if(this.config.data.id!==undefined)
    {
      this.progaminteranlarray = this.config.data.event.filter((x:any)=> {return x.id==this.config.data.id});
      this.synopsis = this.domSanitizer.bypassSecurityTrustHtml(this.progaminteranlarray[0].synopsis);
    }
  }

  private setvalues(app: any, program:any) {
    this.LTITLE=     app['LTITLE'];
    this.SHARE =     app['SHARE'];
    this.REGIE =     program['REGIE'];
    this.COUNTRY =   program['COUNTRY'];
    this.LENGTH  =   program['LENGTH'];
    this.DATEEVENT = program['DATEEVENT'];
    this.YEAR =      program['YEAR'];
    this.LYEAR =     program['LYEAR'];
    this.LMOVIE =    program['LMOVIE'];
    this.GENRE =     program['GENRE'];
    this.STARS =     program['STARS'];
    this.TAGLINE =   program['TAGLINE'];
    this.AWARDS =    program['AWARDS'];
    this.PRICE =     program['PRICE'];
    this.PRICEFOLLOW = program['PRICEFOLLOW'];
    this.TRAILER =   program['TRAILER'];
  }

  private GetLanguageData() {
    const app = this._translate.instant('APP');
    const program = this._translate.instant('PROGRAM');
    this.setvalues(app, program);
  }
}
