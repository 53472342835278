import { IiOsInfo } from "../core/model/ios-info.interface";


export class iOS{
    
  public static iOsInfo(userAgent:string) :IiOsInfo {

    const regex = /(?<device>(ipad|iphone)+).*os\s(?<ios>(1[0-9][_|\.][0-9][0-9]?|1[7-9]|2[0-9])).*(?<browser>(safari)+)/i;

    let os16_4_1_or_higher = false;
    let isIos: boolean = false;
    let isSafari: boolean = false;
    let deviceType: string = "";
    const match = userAgent.match(regex);
    if (match !== null) {
      for (const name in match.groups) {
        if (name === "device")
        {  isIos = true;
            deviceType = match.groups[name];
        }
        if (name === "ios") {
          const version: number = parseFloat(match.groups[name].replace("_", "."));
          if (version >= 16.4)
            os16_4_1_or_higher = true;
        }
        if (name === "browser")
          isSafari = true;
      }
    }
    return { isIos, os16_4_1_or_higher, isSafari,deviceType };
    }
}