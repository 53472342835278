import { Component, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SpreadShopJSLoader } from 'src/app/helper/SpreadShopJSLoader';
// import * as myExtObject from '../../../assets/js/shopclient.js';
// declare var myExtObject: any;
declare global {
  interface Window {
    spread_shop_config?: () => void;
  }
}

@Component({
  selector: 'kruh-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css'],

})
export class ShopComponent implements OnInit {
  ready = new EventEmitter<string>();
  constructor(
    public  _loader: SpreadShopJSLoader,
    private  _translate: TranslateService ) {
    let l = this._translate.currentLang;
    let test = (window as { [key: string]: any })["spread_shop_config"] as any;
    test = {
      "shopName": "filmfestkuh",
      "locale" :`${l}_${l.toUpperCase()}`,
      "prefix" : `//shop.spreadshirt.${l=='en'?'co.uk':l}`,
      "baseId" : "filmfestkuhshop"
    };
   (<any>window).spread_shop_config=test;

   }

  ngOnInit(): void {
    this._loader.load("SpreadShopReady").then(() =>  console.log( "myExtObject"));

  }
}



