import { formatDate } from "@angular/common";
import { SortData } from "./sort-data-by-name";

export class Filter{
    

      public static  ByValue(array:any[], string:string) {
        let filtered : any[] = [];
       let d= array.filter(o =>
            Object.keys(o).some((k:any) =>
              {
                if(typeof o[k] === 'string'  && (k === 't' || k === 'title') && o[k].toLowerCase().startsWith(string.toLowerCase()))
                {
                  //if(typeof o[k] === 'number'  && k === 'lcid'  && o[k] === +this.lcidName.value.code)
    
                  filtered.push(o[k]);
                }
               return (typeof o[k] === 'string'  &&  (k === 't' || k === 'title') && o[k].toLowerCase().startsWith(string.toLowerCase()))
              }
              )  );
              return filtered;
      }
      public static  ByValueForAC(array:any[], string:string) {
        let filtered : any[] = [];
        const arraysorted : any[] = SortData.By(array,"eventdate");
       let d= arraysorted.filter(o =>
      
            Object.keys(o).some((k:any) =>
              {
                if(typeof o[k] === 'string'  && ( k === 'title') && o[k].toLowerCase().startsWith(string.toLowerCase()))
                {
                  let d:Date= new Date(o['eventdate']*1000);
                  
                  const date= formatDate(d,'EEE\. d MMMM yyyy H:mm','nl-NL', '+0200')
    
                  filtered.push(`${o['id']}# ${o[k]} | ${o['lcid']} - ${date}`);
                }
               return (typeof o[k] === 'string'  &&  (  k === 'title') && o[k].toLowerCase().startsWith(string.toLowerCase()))
              }
              )  );
              return filtered;
      }
      public static  ByValueForACprogram(array:any[], string:string, lcid:string) {

        const locale:string=`${lcid}-${lcid.toUpperCase()}`;
        let filtered : any[] = [];
        const arraysorted : any[] = SortData.By(array,"eventdate");
       let d= arraysorted.filter(o =>
      
            Object.keys(o).some((k:any) =>
              {
                if(typeof o[k] === 'string'  && ( k === 'title') )
                {
                  let d:Date= new Date(o['eventdate']*1000);
                  
                  const date= formatDate(d,'EEE\. d MMMM yyyy H:mm',locale, '+0200')
    
                  filtered.push(`${o[k]} - ${date} #${o['id']}`);
                }
               return (typeof o[k] === 'string'  &&  (  k === 'title') && o[k].toLowerCase().startsWith(string.toLowerCase()))
              }
              )  );
              return filtered;
      }
      public static  ByValueForACcontent(array:any[], string:string, lcid:string) {

        const locale:string=`${lcid}-${lcid.toUpperCase()}`;
        let filtered : any[] = [];
        const arraysorted : any[] = SortData.By(array,"year");
       let d= arraysorted.filter(o =>
      
            Object.keys(o).some((k:any) =>
              {
                if(typeof o[k] === 'string'  && ( k === 'title') )
                {
                  let y:number= o['year'];
    
                  filtered.push(`${o[k]} - ${y} #${o['id']}`);
                }
               return (typeof o[k] === 'string'  &&  (  k === 'title') && o[k].toLowerCase().startsWith(string.toLowerCase()))
              }
              )  );
              return filtered;
      }
      public static  ByValueForACvideo(array:any[], string:string) {

        // const locale:string=`${lcid}-${lcid.toUpperCase()}`;
         let filtered : any[] = [];
         let d= array.filter(o =>
      
            Object.keys(o).some((k:any) =>
              {
                if(typeof o[k] === 'string'  && ( k === 'title' || k === 'title') )
                {
                  let y:number= o['year'];
    
                  filtered.push(`${o[k]} - ${y} #${o['id']}`);
                }
               return (typeof o[k] === 'string'  &&  ( k === 'title' || k === 'title') && o[k].toLowerCase().startsWith(string.toLowerCase()))
              }
              )  );
              return filtered;
      }
      public static  ToObjectByValue(array:any[], string:string) {
        let filtered : any[] = [];
        let d= array.filter(o =>
            Object.keys(o).some((k:any) =>
              {
                if(typeof o[k] === 'string'  && (k === 't' || k === 'title')  && o[k].toLowerCase().includes(string.toLowerCase()))
                {
    
                  filtered.push(o);
    
                }
               return (typeof o[k] === 'string' && (k === 't' || k === 'title') && o[k].toLowerCase().includes(string.toLowerCase()))
              }
              )  );
              return filtered
      }
      public static  ToObjectByValueForAC(array:any[], string:string) {
        let filtered : any[] = [];
        const id:number = +string.split('#')[0];
        let d= array.filter(o =>
            Object.keys(o).some((k:any) =>
              {
                if(typeof o[k] === 'number'  && ( k === 'id')  && o[k] == id  )
                {
    
                  filtered.push(o);
    
                }
               return (typeof o[k] === 'string' && ( k === 'title') && o[k].toLowerCase().includes(string.toLowerCase()))
              }
              )  );
              return filtered
      }
  }