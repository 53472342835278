import {  Component, OnInit } from '@angular/core';
import { QueryOptions } from 'src/app/core/services/query.options';
import {TranslateService} from '@ngx-translate/core';
import { DestroySubscribers } from 'src/app/core/destroysubscribers';
import { ContentDataService } from 'src/app/core/services/api/editorial.service';
import { IContent } from 'src/app/core/model/content.interface';
@Component({
  selector: 'kruh-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.css']
})
@DestroySubscribers()
export class DatenschutzComponent implements OnInit {


    content:string='';
    arrayData: IContent[]=[];
    title: string='';
    constructor(
      private _dataservices:ContentDataService,
      private  translate: TranslateService
    ) {
      this.translate.onLangChange.subscribe(() => {
        this.GetContent();
      });
    }

    ngOnInit() {
      this.GetContent();
    }

    private GetContent() {
      const dateNow:Date= new Date();
      let o:QueryOptions= new QueryOptions;
      o.type='6';
      o.year =dateNow.getFullYear();
      o.lcid = this.translate.currentLang;
      this._dataservices.list(o).subscribe( (result:IContent[]) =>{
        if(result!=null && result.length>0)
        {
          this.arrayData= result;
          this.content =this.arrayData[0].description;
          this.title=result[0].title;
        }
      });
    }
  }