import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LinkService } from 'src/app/core/services/api/link.services';
import { QueryOptions } from 'src/app/core/services/query.options';
import { SignalrService } from 'src/app/core/services/signalR/SignalrService';
import { ISqlChanged } from 'src/app/core/model/sqlchanged.interface';
import { FindIndexBy } from 'src/app/helper/find-index-by-id';
import { ILink } from 'src/app/core/model/link.interface';

@Component({
  selector: 'kruh-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent implements OnInit {
  linkData:ILink[]= [];
  hubHelloMessage: string='';
  constructor(private linkService: LinkService,
    public signalrService: SignalrService,
    private  translate: TranslateService
   ) {
    this.linkData =[];
   }

  ngOnInit() {

    this.signalrService.hub.subscribe((data: ISqlChanged) => {
      if(data!==null)
      {
        if( data.link && data.link.id)
        {
         const item:ILink=(data.link as ILink);
         const i:number= FindIndexBy.Id(data.link.id,this.linkData);
         if(i!== -1)
         {
           if(!data.link.isdispose)
           this.linkData[FindIndexBy.Id(data.link.id,this.linkData)] = item;
           else{
             this.linkData= this.linkData.filter(obj => obj.id !== item.id);
           }
         }
           else
           {
             if(!data.link.isdispose && this.linkData[0]  && this.linkData[0].lcid ==data.link.lcid)
             this.linkData.push(item);
           }
        }
      }
    });

    let o: QueryOptions = new QueryOptions;
    o.lcid= this.translate.currentLang;
      this.linkService.list(o).subscribe( (result:ILink[]) =>{
      if(result!=null){
        this.linkData=result;
      }
    }
    );
  }

  showUpdatedItem(newItem:ILink){
    let updateItem = this.linkData.find(this.findIndexToUpdate, newItem.id);
    if(updateItem!== undefined)
    {
      let index = this.linkData.indexOf(updateItem);
      this.linkData[index] = newItem;
    }
    else
    {
      if(this.linkData[0]  && this.linkData[0].lcid ==newItem.lcid)
      this.linkData.push(newItem);
    }
  }
  findIndexToUpdate(newItem:any) {
        return newItem.id === this;
  }
}
