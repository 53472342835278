import {Injectable} from '@angular/core';
import { IDropdown } from '../model/dropdown.interface';

@Injectable()
export  class CountryService {
  constructor() {}
  getCountries(lcid:string): IDropdown[] {
    if(lcid=="de")
    return [

      { name: 'Deutschland', code: '1' },
      { name: 'Österreich', code: '2' },
      { name: 'Schweiz', code: '3' },
      { name: 'USA', code: '4' },
      { name: 'Großbritannien', code: '5' },
      { name: 'Frankreich', code: '6' },
      { name: 'Türkei', code: '7' },
      { name: 'Argentinien', code: '8' },
      { name: 'Irland', code: '9' },
      { name: 'Belgien', code: '10' },
      { name: 'Dänemark', code: '11' },
      { name: 'Polen', code: '12' },
      { name: 'Island', code: '13' },
      { name: 'Libanon', code: '14' },
      { name: 'Ukraine', code: '15' },
      { name: 'Spanien', code: '16' },
      { name: 'Norwegen', code: '17' },
      { name: 'Schweden', code: '18' },
      { name: 'Katar', code: '19' },
      { name: 'Kuba', code: '20' },
      { name: 'Israel', code: '21' },
      { name: 'Japan', code: '22' },
      { name: 'Italie', code: '23' },
      { name: 'Niederlande', code: '24' },
      { name: 'China', code: '25' },
      { name: 'Luxemburg', code: '26' },
      { name: 'Kenia', code: '27' },
      { name: 'CSSR', code: '28' },
      { name: 'Peru', code: '29' },
      { name: 'Jamaica', code: '30' },
      { name: 'DDR', code: '31' },
      { name: 'BRD', code: '32' },
      { name: 'Lettland', code: '33' },
      { name: 'Kanada', code: '34' },
      { name: 'Vereinigtes Königreich', code: '35' },
      { name: 'Senegal', code: '36' },
      { name: 'Australien', code: '37' },
      { name: 'Kolumbien', code: '38' },
      { name: 'Neuseeland', code: '39' },
      { name: 'Finnland', code: '40' },
      { name: 'UdSSR', code: '41' }

    ];
    else if(lcid=="nl")
    return [
      { name: 'Duitsland', code: '1' },
      { name: 'Oostenrijk', code: '2' },
      { name: 'Zwitserland', code: '3' },
      { name: 'US', code: '4' },
      { name: 'Groot-Brittannië', code: '5' },
      { name: 'Frankrijk', code: '6' },
      { name: 'Turkije', code: '7' },
      { name: 'Argentinië', code: '8' },
      { name: 'Ierland', code: '9' },
      { name: 'België', code: '10' },
      { name: 'Denemarken', code: '11' },
      { name: 'Polen', code: '12' },
      { name: 'IJsland', code: '13' },
      { name: 'Libanon', code: '14' },
      { name: 'Oekraïne', code: '15' },
      { name: 'Spanje', code: '16' },
      { name: 'Noorwegen', code: '17' },
      { name: 'Zweden', code: '18' },
      { name: 'Qatar', code: '19' },
      { name: 'Cuba', code: '20' },
      { name: 'Israël', code: '21' },
      { name: 'Japan', code: '22' },
      { name: 'Italia', code: '23' },
      { name: 'Nederland', code: '24' },
      { name: 'China', code: '25' },
      { name: 'Luxemburg', code: '26' },
      { name: 'Kenia', code: '27' },
      { name: 'CSSR', code: '28' },
      { name: 'Peru', code: '29' },
      { name: 'Jamaica', code: '30' },
      { name: 'DDR', code: '31' },
      { name: 'BRD', code: '32' },
      { name: 'Letland', code: '33' },
      { name: 'Canada', code: '34' },
      { name: 'Verenigd Koninkrijk', code: '35' },
      { name: 'Senegal', code: '36' },
      { name: 'Australië', code: '37' },
      { name: 'Colombia', code: '38' },
      { name: 'Nieuw-Zeeland', code: '39' },
      { name: 'Finland', code: '40' },
      { name: 'USSR', code: '41' }
    ];
    else
    return [

      { name: 'Germany', code: '1' },
      { name: 'Austria', code: '2' },
      { name: 'Switzerland', code: '3' },
      { name: 'USA', code: '4' },
      { name: 'Great Britain', code: '5' },
      { name: 'France', code: '6' },
      { name: 'Turkey', code: '7' },
      { name: 'Argentina', code: '8' },
      { name: 'Ireland', code: '9' },
      { name: 'Belgium', code: '10' },
      { name: 'Denmark', code: '11' },
      { name: 'Poland', code: '12' },
      { name: 'Iceland', code: '13' },
      { name: 'Lebanon', code: '14' },
      { name: 'Ukraine', code: '15' },
      { name: 'Spain', code: '16' },
      { name: 'Norway', code: '17' },
      { name: 'Sweden', code: '18' },
      { name: 'Qatar', code: '19' },
      { name: 'Cuba', code: '20' },
      { name: 'Israel', code: '21' },
      { name: 'Japan', code: '22' },
      { name: 'Italy', code: '23' },
      { name: 'Netherlands', code: '24' },
      { name: 'China', code: '25' },
      { name: 'Luxembourg', code: '26' },
      { name: 'Kenia', code: '27' },
      { name: 'Czechoslovakia', code: '28' },
      { name: 'Peru', code: '29' },
      { name: 'Jamaica', code: '30' },
      { name: 'DDR', code: '31' },
      { name: 'BRD', code: '32' },
      { name: 'Latvia', code: '33' },
      { name: 'Canada', code: '34' },
      { name: 'United Kingdom', code: '35' },
      { name: 'Senegal', code: '36' },

      { name: 'Australia', code: '37' },
      { name: 'Colombia', code: '38' },
      { name: 'New Zealand', code: '39' },
      { name: 'Finland', code: '40' },
      { name: 'Soviet Union', code: '41' }

    ];
  }
}