import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { MatTab, MatTabChangeEvent } from '@angular/material/tabs';
import { DestroySubscribers } from 'src/app/core/destroysubscribers';
import { TabNames } from 'src/app/helper/enum/tabindex.enum';
import { AppInsightsService } from 'src/app/core/services/app-insights.service';
import { AuthService } from 'src/app/core/services/auth.services';
import { ApiTokenService } from 'src/app/core/services/apitoken/api-token.service';
import { IApiState } from 'src/app/core/services/apitoken/Iapistate';
import { Location } from '@angular/common';

import { AnreiseComponent } from '../anreise/anreise.component';
import { ProgramComponent } from '../program/program.component';
import { AboutComponent } from '../info/about/about.component';
import { LinksComponent } from '../info/link/links.component';
import { ShopComponent } from '../shop/shop.component';
import { BallmertshofenComponent } from '../info/ballmertshofen/ballmertshofen.component';
import { DatenschutzComponent } from '../info/datenschutz/datenschutz.component';
import { ContactComponent } from '../info/contact/contact.component';
import { EditorialComponent } from '../info/editorial/editorial.component';
import { PhotosComponent } from '../fotos/photos/photos.component';
import { VideoComponent } from '../videos/video/video.component';
import { PanoramaComponent } from '../panorama/panorama/panorama.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
@DestroySubscribers()
export class DashboardComponent implements OnInit {
  @ViewChild('home', { read: ViewContainerRef })
  home!: ViewContainerRef;
  @ViewChild('link', { read: ViewContainerRef })
  link!: ViewContainerRef;
  @ViewChild('info', { read: ViewContainerRef })
  info!: ViewContainerRef;

  @ViewChild('anreise', { read: ViewContainerRef })
  anreise!: ViewContainerRef;
  @ViewChild('shop', { read: ViewContainerRef })
  shop!: ViewContainerRef;
  @ViewChild('ballmertshofen', { read: ViewContainerRef })
  ballmertshofen!: ViewContainerRef;
  @ViewChild('datenschutz', { read: ViewContainerRef })
  datenschutz!: ViewContainerRef;
  @ViewChild('contact', { read: ViewContainerRef })
  contact!: ViewContainerRef;
  @ViewChild('editorial', { read: ViewContainerRef })
  editorial!: ViewContainerRef;
  @ViewChild('program', { read: ViewContainerRef })
  program!: ViewContainerRef;
  @ViewChild('photos', { read: ViewContainerRef })
  photos!: ViewContainerRef;
  @ViewChild('videos', { read: ViewContainerRef })
  videos!: ViewContainerRef;
  @ViewChild('panorama', { read: ViewContainerRef })
  panorama!: ViewContainerRef;
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {

    if(tabChangeEvent.tab.textLabel==TabNames.Program)
    {
      this.program.clear();
      this.program.createComponent(ProgramComponent);
       this._insightService.logEvent("PWA",{"Tab":TabNames.Program});
    }
    if(tabChangeEvent.tab.textLabel==TabNames.Info)
    {
      this.info.clear();
      this.info.createComponent(AboutComponent);
       this._insightService.logEvent("PWA",{"Tab":TabNames.Info});
    }
    // if(tabChangeEvent.tab.textLabel==TabNames.Home)
    // {
    //   this.home.clear();
    //    this.lazyLoader.load('home','compHome', this.home);
    //    this._insightService.logEvent("PWA",{"Tab":TabNames.Home});
    // }
    if(tabChangeEvent.tab.textLabel==TabNames.Links)
    {
      this.link.clear();
      this.link.createComponent(LinksComponent);
       this._insightService.logEvent("PWA",{"Tab":TabNames.Links});
    }

    if(tabChangeEvent.tab.textLabel==TabNames.Anreise)
    {
      this.anreise.clear();
      this.anreise.createComponent(AnreiseComponent);
       this._insightService.logEvent("PWA",{"Tab":TabNames.Anreise});
    }
    if(tabChangeEvent.tab.textLabel==TabNames.Shop)
    {
      this.shop.clear();
      this.shop.createComponent(ShopComponent);
       this._insightService.logEvent("PWA",{"Tab":TabNames.Shop});
    }
    if(tabChangeEvent.tab.textLabel==TabNames.ballmertshofen)
    {
      this.ballmertshofen.clear();
      this.ballmertshofen.createComponent(BallmertshofenComponent)
       this._insightService.logEvent("PWA",{"Tab":TabNames.ballmertshofen});
    }
    if(tabChangeEvent.tab.textLabel==TabNames.DATENSCHUTZ)
    {
      this.datenschutz.clear();
      this.datenschutz.createComponent(DatenschutzComponent);
       this._insightService.logEvent("PWA",{"Tab":TabNames.DATENSCHUTZ});
    }
    if(tabChangeEvent.tab.textLabel==TabNames.Contact)
    {
      this.contact.clear();
      this.contact.createComponent(ContactComponent);
       this._insightService.logEvent("PWA",{"Tab":TabNames.Contact});
    }
    if(tabChangeEvent.tab.textLabel==TabNames.Editorial)
    {
      this.editorial.clear();
      this.editorial.createComponent(EditorialComponent);
       this._insightService.logEvent("PWA",{"Tab":TabNames.Editorial});
    }
    if(tabChangeEvent.tab.textLabel==TabNames.Fotos)
    {
      this.photos.clear();
      this.photos.createComponent(PhotosComponent);
       this._insightService.logEvent("PWA",{"Tab":TabNames.Fotos});
    }
    if(tabChangeEvent.tab.textLabel==TabNames.Videos)
    {
      this.videos.clear();
      this.videos.createComponent(VideoComponent);
       this._insightService.logEvent("PWA",{"Tab":TabNames.Videos});
    }
    if(tabChangeEvent.tab.textLabel==TabNames.Panorama)
    {
      this.panorama.clear();
      this.panorama.createComponent(PanoramaComponent);
       this._insightService.logEvent("PWA",{"Tab":TabNames.Panorama});
    }

  }

  tabname: string=TabNames.Program;
  namehome:string=TabNames.Home;
  showhome:boolean=false;
  nameprogram:string=TabNames.Program;
  showprogram:boolean=false;
  namelinks:string=TabNames.Links;
  showlinks:boolean=false;
  nameinfo:string=TabNames.Info;
  showinfo:boolean=false;
  nameanreise:string=TabNames.Anreise;
  showanreis:boolean=false;
  namefotos:string=TabNames.Fotos;
  showfotos:boolean=false;
  namevideos:string=TabNames.Videos;
  showvideos:boolean=false;
  namepanorama:string=TabNames.Panorama;
  showpanorama:boolean=false;
  nameshop:string=TabNames.Shop;
  showshop:boolean=false;
  nameballmertshofen:string=TabNames.ballmertshofen;
  showballmertshofen:boolean=false;
  namedatenschutz:string=TabNames.DATENSCHUTZ;
  showdatschutz:boolean=false;
  namecontact:string=TabNames.Contact;
  showcontact:boolean=false;
  nameeditorial:string=TabNames.Editorial;
  showeditorial:boolean=false;
  pushname:string=TabNames.Push;
  showpush:boolean=false;
  adminname:string=TabNames.Admin;
  showadmin:boolean=false;
  tabindex!: string | null;
  selectedIndex:any
  constructor(
    private _insightService:AppInsightsService,
    private _route:ActivatedRoute,
    private _router:Router,
    private _authservices: AuthService,
    private _apiService:ApiTokenService,
    private _location: Location
  ) {
    this._apiService.apiRole$.subscribe((role:IApiState) => {
      if(role.isAdmin)
      {
        this.showpush=true;
        this.showadmin=true;
      }
    });
    if(this.authenticated && this._apiService.token==undefined) {
      this._apiService.getRoleFromApiToken();
    }
  }

  ngOnInit() {
    this._route.paramMap.subscribe( (params: ParamMap) =>{
      if (params.get('tabindex')!== undefined) {
        const key =params.get('tabindex');
        this.tabindex =key;
        switch (key) {

          case "home":
            this.tabname =TabNames.Home;
            break;
            case "program":
              this.tabname =TabNames.Program;
              break;
            case "links":
              this.tabname =TabNames.Links;
              break;
            case "Info":
              this.tabname =TabNames.Info;
              break;
            case "anreise":
              this.tabname =TabNames.Anreise;
              break;
            case "fotos":
              this.tabname =TabNames.Fotos;
              break;
            case "videos":
              this.tabname =TabNames.Videos;
              break;
            case "panorama":
              this.tabname =TabNames.Panorama;
              break;
            case "shop":
              this.tabname =TabNames.Shop;
              break;
            case "ballmertshofen":
              this.tabname =TabNames.ballmertshofen;
              break;
            case "push":
              this.tabname =TabNames.Push;
              this.showpush=true;
              break;
            case "administration":
              this.tabname =TabNames.Admin;
              this.showadmin=true;
              break;
          default:
            break;
        }
        this._location.replaceState('/dashboard');
      }
     }
    );

  }
  public ngAfterViewInit() {
    // this._router.navigate([ './'], {
    //     relativeTo: this._route
    // });

    let y:MatTabChangeEvent= new MatTabChangeEvent();
    if(this.tabindex ==TabNames.DATENSCHUTZ.toLowerCase())
    {
      y.tab =  new MatTab(this.datenschutz,null);
      y.tab.textLabel= TabNames.DATENSCHUTZ;
      this.selectedIndex=9;
    }
    else if(this.tabindex ==TabNames.Contact.toLowerCase())
    {
      y.tab =  new MatTab(this.contact,null);
      y.tab.textLabel= TabNames.Contact;
      this.selectedIndex=7;
    }
    else if(this.tabindex ==TabNames.Shop.toLowerCase())
    {
      y.tab =  new MatTab(this.shop,null);
      y.tab.textLabel= TabNames.Shop;
      this.selectedIndex=6;
    }
    else if(this.tabindex ==TabNames.Photos.toLowerCase())
    {
      y.tab =  new MatTab(this.photos,null);
      y.tab.textLabel= TabNames.Photos;
      this.selectedIndex=4;
    }
    else if(this.tabindex ==TabNames.Videos.toLowerCase())
    {
      y.tab =  new MatTab(this.videos,null);
      y.tab.textLabel= TabNames.Videos;
      this.selectedIndex=5;
    }
    else if(this.tabindex ==TabNames.Panorama.toLowerCase())
    {
      y.tab =  new MatTab(this.panorama,null);
      y.tab.textLabel= TabNames.Panorama;
      this.selectedIndex=10;
    }
    else if(this.tabindex ==TabNames.Anreise.toLowerCase())
    {
      y.tab =  new MatTab(this.anreise,null);
      y.tab.textLabel= TabNames.Anreise;
      this.selectedIndex=3;
    }
    else if(this.tabindex ==TabNames.Info.toLowerCase())
    {
      this.info.clear();
      y.tab =  new MatTab(this.info,null);
      y.tab.textLabel= TabNames.Info;
      this.selectedIndex=2;
    }
    else if(this.tabindex ==TabNames.Editorial.toLowerCase())
    {
      this.editorial.clear();
      y.tab =  new MatTab(this.editorial,null);
      y.tab.textLabel= TabNames.Editorial;
      this.selectedIndex=1;
    }
    else{
      y.tab =  new MatTab(this.program,null);
      y.tab.textLabel= TabNames.Program;
      this.selectedIndex=0;
      this.tabChanged(y);
    }
  }

  get authenticated(): boolean
  {
    return this._authservices.isLoggedIn();
  }

}
