import { Injectable } from '@angular/core';
import { IPhotosets, Photoset } from '../../model/photoset.interface';
import { FlickrphotoService } from '../filckr/flickrphoto.service';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  private photos!: Promise<Photoset>;
  private photossets!: Promise<IPhotosets>;

  constructor(
    private _flickr: FlickrphotoService
  ) { }



  /**
   * Have to use http.jsonp() to call to Flickr 3rd Party API to avoid CORS (cross origin resource sharing) issue.
   * Returns a JSON object which has property items which includes an array of photos.
   */
  getPhotos(id:string): Promise<any> {
    if (this.photos === undefined) {
   
      this.photos = this._flickr.getPhotos(id) ;
    }
    else
    this.photos = this._flickr.getPhotos(id) ;
    return this.photos;
  }
  getPhotosSets(): Promise<any> {
    if (this.photossets=== undefined) {
      this.photossets = this._flickr.getPhotosSets() ;
    }
    return this.photossets;
  }
}