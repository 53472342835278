<p-card>
    <p-header class="title">{{title}}</p-header>
    <div class="scrollable-content content">
        <div [innerHTML]="content">
      </div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
   
</p-card>
