
<div class="next" *ngIf="upcomming"><h3>{{NEXT}} {{upcomming}}</h3></div>
<p-card>
    <p-header class="title">{{title}}</p-header>
   
    <form [@toggle_p]="toggle" [formGroup]="form" novalidate>
      <label for="yearName">{{PHSELECTYEAR}}</label>
      <div>
        <div class="displayinlineblock">
          <p-dropdown [options]="years" formControlName="yearName" placeholder="{{PHSELECTYEAR}}"
            optionLabel="name"></p-dropdown>
        </div>
        <div class="float-right flex-lt-sm-73">
          <span dir="ltr" class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <p-autoComplete formControlName="titleName" placeholder="{{PHSEARCH}}" [suggestions]="results" [showClear]="true" [size]="50" (onClear)="ACclear()" (onSelect)="ACSelect($event)" (completeMethod)="search($event)" ></p-autoComplete>
          </span>
        </div>
      </div>
   </form>
        <div id="programlist" class="program-content scrollable-content content" [ngClass]="isVisible ? 'program-content' : 'program-content-vh-80 content-vh-75'" scroll (scrollPosition)="scrollChanged($event)">
          <ul class="cert-result padding-left-0 padding-left-0-xs cert-result-100">
            <li class="lt-sm-column" *ngFor="let key of groupedSeriesNames" id="{{key}}">
            <div class="lt-sm-column"><h4>{{key}}</h4></div>
           
            <div class="flex-column-w86-g20">
              <div class="flex-column-g20 pointer" *ngFor="let pi of groupedSeries[key]" (click)="showdetail(pi.id)" id="{{pi.epoch}}">
                <div class="lt-sm-column"><b>{{pi.dateevent| date:'HH:mm'}}</b></div>
                <div class="flex-column-g8">
                  <div [style.background-color]="pi.programtypecolor" [ngClass]="(pi.gid==8)?'kind kind-small':'ptitle ptitle-small'">{{pi.title}}<span *ngIf="pi.gid==8" class="barn"><img src="/assets/img/barn.svg" title="{{BARN}}" alt="{{BARN}}"/></span></div>
                  <div class="regie-img regieblock-gt-sm ">
                  <div  class="italic regieblock-xs regieblock">
                    <div>
                      {{pi.regie}}
                    </div>
                    <div>
                      <ng-container *ngFor="let co of pi.countryNames">
                        {{co.name}}
                      </ng-container>
                      <ng-container *ngIf="pi.gid==6 ||pi.gid==8">
                        {{pi.year}}
                      </ng-container>
                    </div>
                    <div>
                      <ng-container *ngFor="let co of pi.genre">
                        {{co.name}}
                      </ng-container>
                    </div>
                  </div>
                  <div class="programm-img">
                    <div *ngIf="pi.gid==6 ||pi.gid==8">
                      <img src="{{pi.imagesArray[0].name}}" alt="{{pi.title}}" title="{{pi.title}}" loading="lazy">
                    </div>
                  </div>
                </div>
                </div>
                  <div class="programm-img-2">
                    <div *ngIf="pi.gid==6 ||pi.gid==8">
                      <img src="{{pi.imagesArray[0].name}}" alt="{{pi.title}}" title="{{pi.title}}" loading="lazy">
                    </div>
                  </div>
              </div>
            </div>
            </li>
          </ul>
          <div>
            <p>&nbsp;</p>
          </div>
        </div>
</p-card>

