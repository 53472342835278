import {  Component, OnInit} from '@angular/core';
import { QueryOptions } from 'src/app/core/services/query.options';
import {TranslateService, TranslationChangeEvent} from '@ngx-translate/core';
import { DestroySubscribers } from 'src/app/core/destroysubscribers';
import { ContentDataService } from 'src/app/core/services/api/editorial.service';
import { IContent } from 'src/app/core/model/content.interface';

@Component({
  selector: 'kruh-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
@DestroySubscribers()
export class ContactComponent implements OnInit {
  content:string='';
  arrayData: IContent[]=[];
  title: string='';
  TITLE: any;
  INTRO: any;
  EMAIL: any;
  PHONE: any;
  ADDRESS: any;
  NAME: any;
  CONTACT: any;
  constructor(
    private _dataservices:ContentDataService,
    private  _translate: TranslateService
  ) {
    this._translate.onLangChange.subscribe((event:TranslationChangeEvent) => {
      const contact = event.translations['CONTACT'];
      this.setvalues(contact);
      this.replaceData();
    });
    this.GetLanguageData();
  }

  private replaceData() {
    this.content = this.arrayData[0].description.replace("Tel:", this.PHONE).replace("Adres:", this.ADDRESS).replace("Email:", this.EMAIL).replace("Ansprechpartner", this.CONTACT).replace("Ansprechpartner", this.CONTACT);
  }

  ngOnInit() {
    this.GetContent();
  }

  private setvalues(contact: any) {
    this.title= contact['TITLE'];
    this.INTRO= contact['INTRO'];
    this.EMAIL= contact['EMAIL'];
    this.PHONE= contact['PHONE'];
    this.ADDRESS= contact['ADDRESS'];
    this.CONTACT= contact['CONTACT'];
    this.NAME= contact['NAME'];
  }
  private GetLanguageData() {
      const contact = this._translate.instant('CONTACT');
      if(typeof contact !== 'string')
      this.setvalues(contact);
  }

  private GetContent() {
    const dateNow:Date= new Date();
    let o:QueryOptions= new QueryOptions;
    o.type='5';
    o.year =dateNow.getFullYear();
    o.lcid = this._translate.currentLang;
    //o.fields = "id,Desciption,IsPublish,Title,LCID"
    this._dataservices.list(o).subscribe( (result:IContent[]) =>{
      if(result!=null && result.length>0)
      {
        this.arrayData= result;
        this.replaceData();
      }
    }
    );
  }
}
