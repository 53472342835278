import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import {throwError} from 'rxjs';

import { catchError } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { AuthService } from './services/auth.services';
import { compare } from 'fast-json-patch';

@Injectable()
export class PushService {

  name:String="";
  constructor(private http: HttpClient,private _authservices: AuthService, private configService: ConfigService) {
    if(this._authservices.isLoggedIn())
    this.name= this._authservices.getUserName();
  }

  urlBase64ToUint8Array(base64String:string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  addSubscriber(subscription:any) {
    if(this._authservices.isLoggedIn())
    this.name= this._authservices.getUserName().split('@')[0];
    const url = `${this.configService.apiAzure}subscriber`;
    console.log('[Push Service] Adding subscriber '+ this.name)

    let body = {
      action: 'subscribe',
      subscription: subscription,
      uqId:'90',
      UserID :this.name
    }

    return this.http
      .post(url, body).pipe(
        catchError(this.handleError)
      );
  }
  sendNotification(payload:any)
  {
    const url = `${this.configService.apiAzure}notify?OLDid=90`;
    return this.http
    .post(url, payload).pipe(
      catchError(this.handleError)
    );

  }

  deleteSubscriber(subscription:any) {
    const url = `${this.configService.apiAzure}subscriber`;
    console.log('[Push Service] Deleting subscriber')
    if(this._authservices.isLoggedIn())
    this.name= this._authservices.getUserName().split('@')[0];
    let body = {
      action: 'unsubscribe',
      subscription: subscription,
      UserID :this.name,
      uqId:'90'
    }
    let orgbody = {
      action: 'subscribe',
     // subscription: subscription,
      UserID :this.name,
      uqId:'90'
    }
    const patch = compare(orgbody, body);
    return this.http
      .patch(url,JSON.stringify(patch)).pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      errMsg = `${error.statusText || 'Network error'}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
