<div class="container">
  <div class="section">
    <div class="content">
      {{title}}
    </div>
         <div class="scrollable-content content">
            <p>{{intro}}</p>
            <h4>{{whatarecookies}}</h4>
            <p>{{cookiecontent1}}</p>
            <h4>{{usecookies}}</h4>
            <div [innerHtml]="cookiecontent2"></div>
           <h4>{{othercookies}}</h4>
            <p>{{cookiecontent3}}</p>
             <h4>{{controlcookies}}</h4>
         <p [innerHtml]="cookiecontent4"></p>
            <p>&nbsp;</p>
          </div>
      </div>
</div>
