import { AfterViewInit, Component,  ViewChild, ViewContainerRef } from '@angular/core';
import { IDropdown } from 'src/app/core/model/dropdown.interface';
declare var pano2vrPlayer:any;
declare var pano2vrSkin:any;


@Component({
  selector: 'kruh-panorama',
  templateUrl: './panorama.component.html',
  styleUrls: ['./panorama.component.css']
})
export class PanoramaComponent implements AfterViewInit {
  @ViewChild('pano_container', { read: ViewContainerRef })
  panoContainer!: ViewContainerRef;
  panoramas:IDropdown[]=[
    {name:'Jugend und Unter wiese zeltplatz',code:'File0170 Panorama_hdr16H_LA_B8C30_2SS_out'},
    {name:'Filmfest 2012',code:'IMG_8470 Panorama_3_hdr_16H_LA_B10C35_2SS_out'},
    {name:'Wiese',code:'IMG_9486 Panorama_2_hdr16H_LC_B9C25_2SS'},
    {name:'Center',code:'IMG_9435 Panorama_2_hdr16H_LA_B9C25_2SS'},   
    {name:'Filmfest',code:'IMG_8521 Panorama_1_hdr16H_LA_B9C25_2SS_out'},
    {name:'Filmfest Ballmertshofen 2',code:'IMG_8620 Panorama_2_hdr_16H_LA_B9C35_2SS_out'},
    {name:'Fussball Platz',code:'IMG_9284_Panorama_RAW_2_hdr_out'},
    {name:'ObereZelt wiese',code:'IMG_9308 Panorama_hdr_out'},
    {name:'Sontag Morgen',code:'IMG_8572_Panorama_hdr_16H_LA_B9C30_2SS_out'},
    {name:'Jugend Zeltplatz',code:'IMG_9332 Panorama_hdr_out'},
    {name:'Jugend Zeltplatz 1',code:'IMG_9392 Panorama_hdr_out'}
  ];
  panorama: IDropdown[];
  selectedFile!:IDropdown;
  pano!:any;
  constructor() { 
    this.panorama = this.panoramas.sort(() => 0.5 - Math.random()).slice(0,1);
    this.selectedFile = this.panorama[0];
  }
  ngAfterViewInit(): void {
    if (this.panoContainer.length==0) {
      /* DOM AVAILABLE */
     this.pano = new pano2vrPlayer('pano-container');
      const skin = new pano2vrSkin(this.pano);
      this.pano.readConfigUrl(`/assets/xml/${this.panorama[0].code}.xml?v=0`);
      if (navigator.userAgent.indexOf('iPhone') != -1) {
          addEventListener("load", function () {
             //setTimeout(hideURLbar(), 0);
          }, false);
      }
  }
  }


  showfile(event:any)
  {
    if(event)
    {
       this.pano.readConfigUrl(`/assets/xml/${event.value.code}.xml?v=0`);
    }

  }
//  hideUrlBar(){
//     if (window.pageYOffset == 0) {
//         window.scrollTo(0, 1);
//         setTimeout(function () { hideUrlBar(); }, 3000);
//     }
// }


}
// function hideURLbar(): (...args: any[]) => void {
//       if (window.pageYOffset == 0) {
//         window.scrollTo(0, 1);
//       }
//     }

