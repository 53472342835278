<p-card>
    <p-header class="title textoverflow">{{title}}</p-header>
    <form  [@toggle]="toggle" [formGroup]="form" novalidate>
        <label for="yearName">{{PHSELECTYEAR}}</label>
        <div>
          <div>
            <p-dropdown [options]="years" formControlName="yearName" placeholder="{{PHSELECTYEAR}}"
              optionLabel="name"></p-dropdown>
          </div>
          <div class="editorial-search">
            <span  dir="ltr" class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <p-autoComplete formControlName="titleName" placeholder="{{PHSEARCH}}" [suggestions]="results" [showClear]="true" [size]="50" (onClear)="ACclear()" (onSelect)="ACSelect($event)" (completeMethod)="search($event)" ></p-autoComplete>
            </span>
          </div>
        </div> 
    </form>
    <div class="scrollable-content content" [ngClass]="isVisible ? 'program-content' : 'program-content-vh-80'">
        <div id="contentdata" [innerHTML]="content" scroll (scrollPosition)="scrollChanged($event)">
      </div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
</p-card>
