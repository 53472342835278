import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {  IPhotosets,  Photoset } from '../../model/photoset.interface';
interface FlickrResponse {
  readonly photoset: Photoset; //ReadonlyArray<Photo>
}
interface FlickrResponseSet {
  readonly photosets: IPhotosets; //ReadonlyArray<Photo>
}

@Injectable({
  providedIn: 'root'
})
export class FlickrphotoService {

  constructor(
    private http: HttpClient
  ) { }

  private photosSetsUrl:string ='https://www.flickr.com/services/rest/?method=flickr.photosets.getList&api_key=24f732afb48ffcbee576ed92b4b803b6&user_id=84726572%40N07&format=json'
  private photosUrl:string = 'https://www.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=24f732afb48ffcbee576ed92b4b803b6&photoset_id=72157710046331742&user_id=84726572%40N07&format=json'



 //https:\/\/live.staticflickr.com\/65535\/52154152812_e92ba82f09_m.jpg

 // private photosUrl = 'https://api.flickr.com/services/flickr.photos.getInfo?format=json&api_key=24f732afb48ffcbee576ed92b4b803b6'; //&jsoncallback=jsonFlickrApi
 // private photosUrl = 'https://api.flickr.com/services/feeds/photos_public.gne?format=json'; //&jsoncallback=jsonFlickrApi
  private callbackParamKey = 'jsoncallback'; //Angular will assign its own function ng_jsonp_callback_0 as the callback argument for API call in URL

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /**
   * Have to use http.jsonp() to call to Flickr 3rd Party API to avoid CORS (cross origin resource sharing) issue.
   * Returns a JSON object which has property items which includes an array of photos.
   */
  getPhotos(id:string): Promise<any> {
    this.photosUrl = `https://www.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=24f732afb48ffcbee576ed92b4b803b6&photoset_id=${id}&user_id=84726572%40N07&format=json`
    return this.http.jsonp<FlickrResponse>(this.photosUrl, this.callbackParamKey)
      .pipe(     
        map(({ photoset }) =>  photoset),
        catchError(this.handleError<Photoset>('getPhotos', undefined))
      ).toPromise();
  }
  getPhotosSets(): Promise<any> {

    return this.http.jsonp<FlickrResponseSet>(this.photosSetsUrl, this.callbackParamKey)
      .pipe(     
        map(({ photosets }) =>  photosets),
        catchError(this.handleError<IPhotosets>('getPhotosSet', undefined))
      ).toPromise();
  }
}