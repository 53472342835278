<p-card class="_100vh">
    <p-header class="title">{{title}}</p-header>
    <p-dropdown name="dplPhotosets" [options]="setList" optionLabel="name" placeholder="{{PHSELECTALBUM}}" [showClear]="true" (onChange)="onChangeDropdown($event)"></p-dropdown>
    <div id="section-gallery">
        <div id="container">
            <!-- <div class="scrollable-content content"> -->
                <a *ngFor="let photo of photos; index as i;"  (click)="showdetail(i)"
                class="tile">
                    <!-- Using [ngStyle] instead to overcome XSS Cross Site Scripting vulnerability  -->
                    <div class="background-tile" [ngStyle]="{ 'background-image': 'url(' + photo.link + ')' }" >
                      <div class="hover-text">
                        <span>{{photo.title}}</span>
                      </div>
                    </div>
                </a> 
              </div>
    </div>
  </p-card>
