/// <reference types="@types/bingmaps" />
import {  Component} from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { BingMapsAPILoader } from 'src/app/helper/BingMapsAPILoader';
@Component({
  selector: 'kruh-anreise',
  templateUrl: './anreise.component.html',
  styleUrls: ['./anreise.component.css'],
  providers: [BingMapsAPILoader]
})
export class AnreiseComponent {
  lat: string;
  long: string;
  LROUTE: any;
  TITLE: any;
  INFO: any;

  constructor (loader: BingMapsAPILoader,
    private  _translate: TranslateService) {
    this.lat = '48.6709';
    this.long = '10.3711';
    this._translate.onLangChange.subscribe((event:TranslationChangeEvent) => {
      const anreise = event.translations['ANREISE'];
      this.setvalues(anreise);
    });
    this.Getdata();
    loader.load("bingAPIReady").then(() => this.initMap());
  }

  protected initMap() {
   
    var layer = new Microsoft.Maps.Layer();
    var pushpinOptions:Microsoft.Maps.IPushpinOptions = { icon: '/assets/img/logo.png' };


    const options: Microsoft.Maps.IMapLoadOptions = {
      center: new Microsoft.Maps.Location(this.lat, this.long),
      mapTypeId:Microsoft.Maps.MapTypeId.road,
      zoom:17,
      showLogo:false,
    //  disableBirdseye:true,
      credentials: "Ag6C5Ci9VUGz9AIhQyJ7YNwGraanqwy5-V3LK1qGDpdEVPV-MUPBryG18ELoC6g6"
    };
    const domelenent:any=document.getElementById("map");
    if(domelenent)
    {
      const map = new Microsoft.Maps.Map(domelenent, options);
      var pushpin = new Microsoft.Maps.Pushpin(map.getCenter(), pushpinOptions);
      layer.add(pushpin);
      map.layers.insert(layer);
      const infobox = new Microsoft.Maps.Infobox(map.getCenter(), {
        title: "Filmfest",
        maxHeight:300,
        maxWidth:200,
        zIndex:0,
        showPointer:true,
        // offset: new Microsoft.Maps.Point(50,50),
        description: `<p>${this.INFO}<br/>Schlossgarten 9<br/>89561 Ballmertshofen </p>` 
      });
      infobox.setMap(map);
    }
  }

  private setvalues(anreise: any) {
    this.LROUTE= anreise['ROUTE'];
    this.TITLE= anreise['TITLE'];
    this.INFO= anreise['INFO'];
  }
  private Getdata() {
      const anreise = this._translate.instant('ANREISE');
      this.setvalues(anreise);
  }
}
