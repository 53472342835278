import {Injectable} from '@angular/core';
import { IDropdown } from '../model/dropdown.interface';

@Injectable()
export  class YearService {

  constructor() {}
  getYears(): IDropdown[] {
    return [
      { name: '2030', code: '30' },
      { name: '2029', code: '29' },
      { name: '2028', code: '28' },
      { name: '2027', code: '27' },
      { name: '2026', code: '26' },
      { name: '2025', code: '25' },
      { name: '2024', code: '24' },
      { name: '2023', code: '23' },
      { name: '2022', code: '22' },
      { name: '2021', code: '21' },
      { name: '2020', code: '20' },
      { name: '2019', code: '19' },
      { name: '2018', code: '18' },
      { name: '2017', code: '17' },
      { name: '2016', code: '16' },
      { name: '2015', code: '15' },
      { name: '2014', code: '14' },
      { name: '2013', code: '13' },
      { name: '2012', code: '12' },
      { name: '2011', code: '11' },
      { name: '2010', code: '10' },
      { name: '2009', code: '09' },
      { name: '2008', code: '08' },
      { name: '2007', code: '07' },
      { name: '2006', code: '06' },
      { name: '2005', code: '05' },
      { name: '2004', code: '04' },
      { name: '2003', code: '03' },
      { name: '2002', code: '02' },
      { name: '2001', code: '01' },
      { name: '2000', code: '00' },
      { name: '1999', code: '99' },
      { name: '1998', code: '98' },
      { name: '1997', code: '97' },
      { name: '1996', code: '96' },
      { name: '1995', code: '95' },
      { name: '1994', code: '94' },
      { name: '1993', code: '93' },
      { name: '1992', code: '92' },
      { name: '1991', code: '91' },
      { name: '1990', code: '90' },
      { name: '1989', code: '89' },
      { name: '1988', code: '88' },
      { name: '1987', code: '87' },
      { name: '1986', code: '86' },
      { name: '1985', code: '85' },
      { name: '1984', code: '84' },
      { name: '1983', code: '83' },
      { name: '1982', code: '82' },
      { name: '1981', code: '81' },
      { name: '1980', code: '80' },
      { name: '1979', code: '79' },
      { name: '1978', code: '78' }
    ];
  }
  getEditorialYears(): IDropdown[] {
    return this.getYears().filter(l=>{return  ![1979,1978,1981,1980,1982,1984,1985,1986,2015, 2020,2021].includes(+l.name) });
  }
  getProgrammYears(): IDropdown[] {
    return this.getYears().filter(l=>{return  ![1979,1980,1982].includes(+l.name) });
  }
  getVideoYears(): IDropdown[] {
    return this.getYears().filter(l=>{return  ![1978,1979,1980,1981,1982,1983,1984,1985,1986,1987,1988,1989,1990,1991,1992,1993,1994,1995,1996,1997,1998,1999,2000,2001,2002,2003,2004,2009,2011,2015,2017 ,2020].includes(+l.name) });
  }
}
