import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '../alert.service';
import { ResourceService } from './resource.service';
import { ConfigService } from '../../config.service';
import { IVideo } from '../../model/video.interface';
@Injectable()
export class VideoService extends ResourceService<IVideo> {
  constructor(
    httpClient: HttpClient,
    _alertServices:AlertService,
    private configService: ConfigService) {
    super(
      httpClient,
      configService.apiAzure,
      _alertServices,
      'video'
      );
  }
}