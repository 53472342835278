import { Component, HostListener, EventEmitter, Output } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.services';
import { NavItem } from 'src/app/core/model/navitem.interface';
import { ApiTokenService } from 'src/app/core/services/apitoken/api-token.service';
import { IApiState } from 'src/app/core/services/apitoken/Iapistate';

@Component({
  selector: 'app-menu-item',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class MenuComponent {
  public  items: NavItem[]=[];
  @Output() toggle: EventEmitter<Boolean> = new EventEmitter();

  title:string='';
  itemMenuManager: NavItem[]=[];
  itemMenuManager1: NavItem[]=[];
  itemsmenu: any;
  roles: IApiState={isAdmin:false,isInvoerder:false,isTester:false,hasToken:false};
  constructor(
    private _authservices: AuthService,
    private _apiService:ApiTokenService,
    private  _translate: TranslateService) {
      this.itemMenuManager=[
        {header:"Management",displayName:"Links", routerLink:'/management/link' , iconName:"folder"},
        {displayName:"Edition", routerLink:'/management/edition' , iconName:"folder"},
        {displayName:"Content", routerLink:'/management/content' , iconName:"folder"},
        {displayName:"Program", routerLink:'/management/program' , iconName:"folder"},
        {displayName:"Videos", routerLink:'/management/video' , iconName:"folder"},
        {displayName:"Period", routerLink:'/management/period' , iconName:"folder"}
      ];
      this.itemMenuManager1 =[
        {displayName:"Webpush", routerLink:'/management/webpush', iconName:"folder"}
      ];
      this._translate.onLangChange.subscribe((event:TranslationChangeEvent) => {
        const app =  event.translations['APP'];
        this.title= app['TITLE'];
        if(this.roles.isAdmin||this.roles.isInvoerder){
          const menu =  event.translations['MENU'];
          this.items=[]; //Clear the menu
          menu.items.forEach((item:any) => {
            this.addItem(item);
          });
          if(this.roles.isAdmin)
          this.itemMenuManager1.forEach(item => {
            this.addItem(item);
          });
        }
      });
      // Management Menu
      this._apiService.apiRole$.subscribe((role:IApiState) => {
        this.roles=role;
        if(role.isAdmin||role.isInvoerder)
        this.itemMenuManager.forEach(item => {
          this.addItem(item);
        });
        if(role.isAdmin)
        this.itemMenuManager1.forEach(item => {
          this.addItem(item);
        });
      });
      if(this.authenticated && this._apiService.token==undefined) {
        this._apiService.getRoleFromApiToken();
      }
  }

  //Controle omdat bij token exp anders een twee keer het menu wordt toegevoegd
  addItem(item:any) {
    var index = this.items.findIndex(x => x.displayName == item.displayName)
    index ===-1?this.items.push(item):this.test();
  }
  get authenticated(): boolean
  {
    return this._authservices.isLoggedIn();
  }
  @HostListener('click')
  click() {
    this.toggle.emit(true);
  }
  // clickItem(event:any)
  // {
  //   let l:string = event['item']['title'];
  //   this.titleHeader= l.toUpperCase();
  // }
  test(): void
  {  }
}
