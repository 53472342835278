export enum TabNames {
    Program = "PROGRAM",
    Home="HOME",
    Links="LINKS",
    Anreise="ANREISE",
    Info="INFO",
    Fotos="FOTOS",
    Videos="VIDEOS",
    Panorama="PANORAMA",
    Shop="SHOP",
    ballmertshofen="BALLMERTSHOFEN",
    DATENSCHUTZ="DATENSCHUTZ",
    Contact="CONTACT",
    Editorial="EDITORIAL",
    Photos="PHOTOS",
    Push="PUSH",
    Admin="ADMIN"
  }