import {Injectable, EventEmitter} from '@angular/core';
@Injectable()
export class YoutubeApiService {
    private iframeScriptId: string = "yt-iframe-api";
    public apiEmitter: EventEmitter<any> = new EventEmitter<any>();

    constructor(
      
    ) { 

    }
    /**
     * Load the Youtube iframe API into the DOM to get access.
     * Stream the YT object to inform all listeners when it's ready.
     */
    loadApi() {
        // Load API only once
        if( window.document.getElementById(this.iframeScriptId) == null ) {
            window.onYouTubeIframeAPIReady = () => {
                this.apiEmitter.emit(YT);
              };
            // Create scripte element and load API
            let apiScriptTag = window.document.createElement("script");
            apiScriptTag.type = "text/javascript";
            apiScriptTag.src = "https://www.youtube.com/iframe_api";
            apiScriptTag.id = this.iframeScriptId;
            window.document.body.appendChild(apiScriptTag);
        } // END OF if( window.document.getElementById(this.iframeScriptId) == null ) { ... }

        // Stream the YT code (which contains the js youtube framework)
        // Notice: window.YT.Player needs to be initialized WITHIN the scope of onYouTubeIframeAPIReady
        // let test = (window as { [key: string]: any })["onYouTubeIframeAPIReady"] as any;
        // test = () => {
        //     // Emit the youtube player Object so it can be used by all subscribing players
        //     console.log("Emit the youtube player Object so it can be used by all subscribing players")
        //    this.apiEmitter.emit(YT);
        // };
    }

}
declare global {
    interface Window {
      onYouTubeIframeAPIReady?: () => void;
    }
  }
