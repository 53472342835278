
<div id="display" >
    <figure id="imgFull">
        <button mat-icon-button class="close-button" title="{{CLOSE}}" (click)="close()">
            <mat-icon class="close-icon" color="info">close</mat-icon>
        </button>
        <img *ngIf="photo" src="{{photo.link.replace('_m', '_b')}}" alt="{{photo.title}}" />
        <figcaption id="heading">
            <span *ngIf="photo">{{photo.title}}</span>
        </figcaption>
        <div class="galleria-image-nav">
            <button pButton pRipple type="button" icon="pi pi-angle-left" class="p-button-rounded p-button-outlined galleria-btn-left" title="{{txtprevious}}" (click)="previous()" ></button>
            <button pButton pRipple type="button" icon="pi pi-angle-right" class="p-button-rounded p-button-outlined galleria-btn-right" title="{{txtnext}}" (click)="next()"></button>
        </div>
    </figure>
 </div>
