import {Component, EventEmitter, Input, Output,  ElementRef, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { DestroySubscribers } from 'src/app/core/destroysubscribers';
import { YoutubeApiService } from 'src/app/core/services/youtube.service';
import { YoutubePlayer } from 'src/app/helper/youtubeplayer';

@Component({
  selector: 'youtube-player',
  templateUrl: './youtube-player.component.html',
  providers: [YoutubePlayer]
})
@DestroySubscribers()
export class YoutubePlayerComponent implements OnInit ,AfterViewInit{
  @Input() videoId: string = '';
  @Input() height: string="240";
  @Input() width: string="320";

  // Player (emitter) created and initialized - sends instance of the player
  // Emit player "out of this component" so that other components can take control of the player
  @Output() ready = new EventEmitter<YT.Player>();
  // state change: send the YT event with its state (like "END, ISPLAYING, PAUSED, ...)"
  @Output() change = new EventEmitter();
  elementId!:string;

  @ViewChild('playercontainer', {static: true}) private playercontainer!: ElementRef;

  constructor(
      public youtubeApiService: YoutubeApiService,
      public youtubePlayer: YoutubePlayer
  ) {

  }

  ngOnInit () {
      // Get original div and set a unique ID for it.
      // That is important when working with multiple players
      this.elementId = this.youtubePlayer.generateUUID();
      this.playercontainer.nativeElement.setAttribute('id', this.elementId);
  }

  ngAfterViewInit() {

    // Load the Youtube API
    this.youtubeApiService.loadApi();

    // Define configuration for the player
      let playerConfig = {
        elementId: this.elementId,
        width: +this.width,
        height: +this.height,
        videoId: this.videoId,
        outputs: {
            ready: this.ready,
            change: this.change
        }
    };
    // Load the youtube player
    this.youtubePlayer.load(playerConfig);
  }
}
