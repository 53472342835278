import { HttpClient } from '@angular/common/http';
import { ResourceService } from './resource.service';
import { ConfigService } from '../../config.service';
import { Injectable } from '@angular/core';
import { AlertService } from '../alert.service';
import { IProgram } from '../../model/program.interface';
@Injectable()
export class ProgramDataService extends ResourceService<IProgram> {
  constructor(httpClient: HttpClient,
    _alertServices:AlertService,
    configService: ConfigService
    ) {
      super(
        httpClient,
        configService.apiAzure,
        _alertServices,
         'program'
        );
  }
}