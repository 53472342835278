import {Injectable} from '@angular/core';
import { IDropdown } from '../model/dropdown.interface';

@Injectable()
export  class GenreService {
  constructor() {}
  getGenre(lcid:string): IDropdown[] {
    if(lcid=="de")
    return [
        { name: 'Drama', code: '1' },
        { name: 'Dokumentarfilm', code: '2' },
        { name: 'Romanze', code: '3' },
        { name: 'Familie', code: '4' },
        { name: 'Thriller', code: '5' },
        { name: 'Komödie', code: '6' },
        { name: 'Geschichte', code: '7' },
        { name: 'Biografie', code: '8' },
        { name: 'Krieg', code: '9' },
        { name: 'Animation', code: '10' },
        { name: 'Western', code: '11' },
        { name: 'Natur', code: '12' },
        { name: 'WorldCinema', code: '13' },
        { name: 'SF', code: '14' },
        { name: 'Jugend', code: '15' },
        { name: 'Abenteuer', code: '16' },
        { name: 'Kriminalität', code: '17' },
        { name: 'Buch', code: '18' },
        { name: 'Musik', code: '19' },
        { name: 'Fantasie', code: '21' },
        { name: 'FilmNoir', code: '22' },
        { name: 'Horror', code: '23' },
        { name: 'Musical', code: '24' },
        { name: 'Geheimnis', code: '25' },
        { name: 'Sport', code: '26' },
        { name: 'Aktion', code: '27' }
    ];
    else if(lcid=="nl")
      return [
        { name: 'Drama', code: '1' },
        { name: 'Documentaire', code: '2' },
        { name: 'Romantiek', code: '3' },
        { name: 'Familie', code: '4' },
        { name: 'Thriller', code: '5' },
        { name: 'Komedie', code: '6' },
        { name: 'Geschiedenis', code: '7' },
        { name: 'Biografie', code: '8' },
        { name: 'Oorlog', code: '9' },
        { name: 'Animatie', code: '10' },
        { name: 'Westers', code: '11' },
        { name: 'Natuur', code: '12' },
        { name: 'WorldCinema', code: '13' },
        { name: 'SF', code: '14' },
        { name: 'Jeugd', code: '15' },
        { name: 'Avontuur', code: '16' },
        { name: 'Misdaad', code: '17' },
        { name: 'Boek', code: '18' },
        { name: 'Muziek', code: '19' },
        { name: 'Fantasie', code: '21' },
        { name: 'FilmNoir', code: '22' },
        { name: 'Horror', code: '23' },
        { name: 'Musical', code: '24' },
        { name: 'Mysterie', code: '25' },
        { name: 'Sport', code: '26' },
        { name: 'Actie', code: '27' }
      ];
   else
       return [
        { name: 'Drama', code: '1' },
        { name: 'Documentary', code: '2' },
        { name: 'Romance', code: '3' },
        { name: 'Family', code: '4' },
        { name: 'Thriller', code: '5' },
        { name: 'Comedy', code: '6' },
        { name: 'History', code: '7' },
        { name: 'Biography', code: '8' },
        { name: 'War', code: '9' },
        { name: 'Animation', code: '10' },
        { name: 'Western', code: '11' },
        { name: 'Nature', code: '12' },
        { name: 'WorldCinema', code: '13' },
        { name: 'SF', code: '14' },
        { name: 'Youth', code: '15' },
        { name: 'Adventure', code: '16' },
        { name: 'Crime', code: '17' },
        { name: 'Book', code: '18' },
        { name: 'Music', code: '19' },
        { name: 'Fantasy', code: '21' },
        { name: 'FilmNoir', code: '22' },
        { name: 'Horror', code: '23' },
        { name: 'Musical', code: '24' },
        { name: 'Mystery', code: '25' },
        { name: 'Sport', code: '26' },
        { name: 'Action', code: '27' }
      ];
  }
}