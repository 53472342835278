import { Component, ChangeDetectorRef,AfterViewChecked, OnDestroy, OnInit,  Renderer2, ApplicationRef, HostListener  } from '@angular/core';
import { Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, Router } from '@angular/router';
import { Subscription,of as observableOf, Observable, interval, concat, of, timer} from 'rxjs';

import { AppInsightsService } from './core/services/app-insights.service';
import { environment } from 'src/environments/environment';
import {  SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { TranslateService } from '@ngx-translate/core';
import {  filter, map } from 'rxjs/operators';
import { Title, Meta,} from '@angular/platform-browser';
import { MessageService, Message } from 'primeng/api';
import { WindowRef } from './window-ref';
import { CookieService } from 'ngx-cookie-service';
import { first, tap } from 'rxjs/operators';
import { LoaderService } from './core/loader/loader.service';
import { NavItem } from './core/model/navitem.interface';
import { SignalrService } from './core/services/signalR/SignalrService';
import * as signalR from '@microsoft/signalr';
import { IiOsInfo } from './core/model/ios-info.interface';
import { iOS } from './helper/ios-info';
import { ISqlChanged } from './core/model/sqlchanged.interface';
import { ICounter } from './core/model/counter.interface';
import { TextSelectionService } from './helper/selected-text';


@Component({
  selector: 'kruh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements  AfterViewChecked, OnDestroy,OnInit {
  private subscription: Subscription;
  isSpinnerVisibile$: Observable<boolean> = this._loaderService.isNavigationPending$;
  isFetching: Observable<boolean>=observableOf(false);
  title = 'Filmfest - Ballmertshofen';
  loading: boolean= false;
  isIOS:Boolean;
  messageonOff!:Message;
  message!:Message;
  messageTap2Ipad!:string;

  installnow!:string;
  updateavailable!:string;
  connected!:string;
  disconnected!:string
  online!:string;
  offline!:string;
  bgclass!:string;
  isIframe!: boolean;
  isiproduct: boolean=false;
  subcriptionsw!: Subscription;
    //keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription!: Subscription;
    private popupCloseSubscription!: Subscription;
    private initializeSubscription!: Subscription;
    private statusChangeSubscription!: Subscription;
    private revokeChoiceSubscription!: Subscription;
    private noCookieLawSubscription!: Subscription;
  deferredPrompt: any;
  DONTASK: any;
  NO: any;
  YES: any;
  sideBarIsOpened: boolean=false;
  navitems: NavItem[]=[];
  lastbg: any;
  counter$: any;
  isinstall: boolean= false;
  currentversion!:string;
  newversion!:string;
    constructor(   
      private router: Router,
      private swUpdate: SwUpdate,
      private ccService: NgcCookieConsentService,
      private translateService:TranslateService,
      private titleService: Title,
      private metaService: Meta,
      private cd: ChangeDetectorRef,

      private messageService: MessageService,
      private winRef: WindowRef,
      private renderer: Renderer2,
      private cookies:CookieService,
      private _messageService: MessageService,
      private _insightsService: AppInsightsService,
      private appRef: ApplicationRef,
      private _loaderService: LoaderService,
      private _signalrService:SignalrService,
       //private _texttospeechService:TextSelectionService,


       )
  {
    this.ccService =ccService;
    this.bgclass =`bg${Math.floor(Math.random() * 13) + 1}`;
    this.renderer.addClass(document.body, this.bgclass);
            //Bepalen tonen installatie scherm
    this.lastbg = this.winRef.nativeWindow.localStorage.getItem("installfirst");
    if(this.lastbg!==null)
     {
       if(+this.lastbg===1)
       {
         this.winRef.nativeWindow.localStorage.setItem("installfirst","2");

       }
       if(+this.lastbg===2)
       {
         this.winRef.nativeWindow.localStorage.setItem("installfirst","3");

       }
       if(+this.lastbg < 3)
       {
         if(environment.production && this.swUpdate.isEnabled)
         {
           this.InstallBrowserApp();
           this.showInstallForiOS();
         }
       }
     }
     else{
        this.winRef.nativeWindow.localStorage.setItem("installfirst","1");
     }
        const isIOS = () => {
          const userAgent = window.navigator.userAgent.toLowerCase();
          return /iphone|ipad|ipod/.test( userAgent );
        }
         this.isIOS =isIOS();
        //  this.logServices.buildPublisher();

          this.subscription =  router.events.subscribe(
            ( routerEvent: Event)=>{
                  this.checkRouterEvent(routerEvent);
            });
  }

  ngOnInit(): void {
        this.isIframe = window !== window.parent && !window.opener;

        if(environment.production && this.swUpdate.isEnabled){
      //   this.subcriptionsw =   this.swUpdate.versionUpdates.subscribe((event:any) => {
      //      this._messageService.clear();
      //      let changlog:any= event.available.appData['changelog'];
      //      const changDate = event.available.appData['changeDate'];
      //      const regexp = new RegExp('^(\\d{4})(\\d{2})(\\d{2}).(\\d+)$');
      //      const result = changDate.match(regexp);
      //      let version ="";
      //      if(result && result.length>3)
      //      {
      //        version =`${result[3]}-${result[2]}-${result[1]} | build ${result[4]}\n\n`;
      //        this.winRef.nativeWindow.localStorage.setItem("version",`${changlog}${version}`);
      //      }
      //      this._messageService.clear();
      //      this._messageService.add({key: 'update', sticky: true, severity:'warn', summary:`${this.updateavailable}`, detail:`${changlog}${version} ${this.installnow}`});
      //  });
       this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => (
          {
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))).subscribe((evt) => {
  
          try{
            const regexp = new RegExp('^(\\d{4})(\\d{2})(\\d{2}).(\\d+)$');
            let versioncurrent ="";
            try {
              if(evt.current.appData!==undefined)
              {
                let { changelog ,changeDate}:any = evt.current.appData;
                versioncurrent =`${changelog}`.replace('versie',this.currentversion);
    
              }
            }
            catch(e)
            { console.log(e);
            };

            if(evt.available.appData!==undefined)
            {
            let { changelog ,changeDate, changeType}:any = evt.available.appData;
  
            const result = changeDate.match(regexp);
            let version ="";
            if(result && result.length>3)
            {
              version =`${result[3]}-${result[2]}-${result[1]} | build ${result[4]}\n\n`;
              this.winRef.nativeWindow.localStorage.setItem("version",`${changelog}${version}`);
              this.winRef.nativeWindow.localStorage.setItem("hash",`${evt.available.hash}`);
            }
            this._messageService.clear();
            this._messageService.add({key: 'update', sticky: true, severity:'warn', summary:`${this.updateavailable}`, detail:`${changeType}${versioncurrent}${changelog.replace('versie',this.newversion)}${version} ${this.installnow}`});
            }
          }
          catch(e)
          { console.log(e);
    
          };
      });
 }

       const appIsStable$ = this.appRef.isStable.pipe(first(stable => stable)
       ,tap(stable =>{
         
         })
       );
        appIsStable$.subscribe(()=>{
          if( this._signalrService.state!=signalR.HubConnectionState.Connected && this._signalrService.state!=signalR.HubConnectionState.Connecting)
          this._signalrService.initiateSignalrConnection();
          this.cleanUpCache();
        });
         const every30minutes$ = interval( 180 * 1000);
         const everySixHoursOnceAppIsStable$ = concat(appIsStable$, every30minutes$);
         everySixHoursOnceAppIsStable$.subscribe(() => {
           this.checkForUpdate();
         });
         this._signalrService.hub.subscribe((data: ISqlChanged) => {
          if(data!==null)
          { 
            if( data.counter)
           {
            const counter:ICounter=(data.counter as ICounter);
            this.counter$= of(counter.online)
           }
          }
        });
        this.translateService.addLangs(['en', 'nl','de', 'fr']);
        this.translateService.setDefaultLang('en');

        const browserLang = this.translateService.getBrowserLang();
        this.translateService.use(browserLang?.match(/en|nl|de|fr/) ? browserLang : 'en');
        this.sideBarIsOpened = true;

        this.GetLanguageText();

        if (window.addEventListener ) {
          /*
              Works well in Firefox and Opera with the
              Work Offline option in the File menu.
              Pulling the ethernet cable doesn't seem to trigger it.
              Later Google Chrome and Safari seem to trigger it well
          */
          window.addEventListener("online", () =>{this.isOnline()}, false);
          window.addEventListener("offline", () =>{this.isOffline()} , false);
        }

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        this.ccService.getConfig()
        // you can use this.ccService.getConfig() to do stuff...
        // console.log('popupOpen');
      });
      this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
        () => {
                      // you can use this.ccService.getConfig() to do stuff...
          console.log('popuClose');
        });

      this.initializeSubscription = this.ccService.initialize$.subscribe(
        (event: NgcInitializeEvent) => {
          var type = this.ccService.getConfig().type;
          var didConsent = event.status== "allow";
          if (type == 'opt-in' && didConsent) {
            // enable cookies
          }
          if (type == 'opt-out' && !didConsent) {
            // disable cookies
          }
          // console.log(`initialize: ${JSON.stringify(event)}`);
        });

      this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        (event: NgcStatusChangeEvent) => {
          var type = this.ccService.getConfig().type;
          var didConsent =event.status== "allow";
          if (type == 'opt-in' && didConsent) {
            // enable cookies
          }
          if (type == 'opt-out' && !didConsent) {
            // disable cookies
          }
          console.log(`statusChange: ${JSON.stringify(event)}`);
        });

      this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        () => {
          // you can use this.ccService.getConfig() to do stuff...
          console.log(`revokeChoice`);
        });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        (event: NgcNoCookieLawEvent) => {
          var type = this.ccService.getConfig().type;
          if (type == 'opt-in') {
            // disable cookies
          }
          if (type == 'opt-out') {
            // enable cookies
          }
          console.log(`noCookieLaw: ${JSON.stringify(event)}`);
        });

        this.translateService.onLangChange.subscribe(() => {
          this.GetLanguageText();
        });

}
  public getRouterOutletState(outlet:any) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
  private checkForUpdate() {
    if(environment.production && this.swUpdate.isEnabled)
     this.swUpdate.checkForUpdate();
  }
  private isOnline() {
    this.messageService.clear();
    this.messageService.add({key:"onoff" ,severity:'success', summary: 'Online', detail:'Connected'});

  }
  private isOffline() {
    this.messageService.clear();
    this.messageService.add({key:"onoff" ,severity:'warn', summary: 'Offline', detail:'No Connection'});
  }

  private cleanUpCache() {
    try{
      caches.keys().then((cacheNames:string[]) => {
        return Promise.all(
          cacheNames.filter(cacheName =>
               cacheName.indexOf(`ngsw:/:${this.winRef.nativeWindow.localStorage.getItem("hash")}`)==-1 &&
               cacheName.indexOf(`ngsw:/:db:${this.winRef.nativeWindow.localStorage.getItem("hash")}`)==-1 &&
               cacheName.indexOf('control')==-1 &&
              cacheName.indexOf('api')==-1
            // Return true if you want to remove this cache,
            // but remember that caches are shared across
            // the whole origin
          ).map(cacheName =>{
            return caches.delete(cacheName);
          })
        );
      });
    }
    catch(e)
    {console.log(e);};
  }

  @HostListener('window:beforeunload', ['$event'])
        unloadHandler() {
        this._signalrService.stopSignalrConnection();
  }

   private showInstallForiOS() {

    const userAgent = window.navigator.userAgent.toLowerCase();
    let { isIos, os16_4_1_or_higher, isSafari ,deviceType}:IiOsInfo = iOS.iOsInfo(userAgent);
    if (isIos) {
      if (!this.winRef.standalone && isSafari && deviceType!=="ipad") {
        timer(10_000, 3600_000).subscribe(n=> this.isinstall = true);
      }
      else if (!this.winRef.standalone && isSafari) {

        this.showInstalPWA(deviceType );
      }
    }
  }
  showInstalPWA(deviceType:string) {
    this._messageService.clear();
    this.isiproduct=true;
    if(deviceType==="ipad"){
    this.message.data=this.messageTap2Ipad;
    this._messageService.add(this.message);
    }
  }

  private InstallBrowserApp() {

    if ((navigator as any).standalone == undefined) {
      // It's not iOS
      if (window.matchMedia('(display-mode: browser').matches) {
          // We are in the browser
          window.addEventListener('beforeinstallprompt', event => {
            // prevent the mini-infobar by calling
              event.preventDefault();
              this.deferredPrompt = event;
              this._messageService.clear();
              this._messageService.add({key: 'install', sticky: true, severity:'', summary:this.message.summary, detail:this.message.detail});
              return false;
          });
      }
    }
  }

  onConfirm()
  {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();

      this.deferredPrompt.userChoice.then((result:any )=> {
              if (result.outcome == 'dismissed') {

                this._insightsService.logEvent("PWA",{"install":"It was NOT installed"});
              } else {
                this._insightsService.logEvent("PWA",{"install":"It was installed"});
              }
              this.winRef.nativeWindow.localStorage.setItem("installfirst","3");
              this.deferredPrompt = null;
          });
      }
  }
  onReject() {
    this.winRef.nativeWindow.localStorage.setItem("installfirst","3");
    this._messageService.clear('install');
  }
  onRejectUpdate(){
    this._messageService.clear('update');
  }
  onConfirmUpdate()
  {
    this._messageService.clear('update');
    this.cleanUpCache();
    this.swUpdate.activateUpdate().then(() => {
      location.reload();
    })
  }


  checkRouterEvent(routerEvent:Event): void{
  if(routerEvent instanceof NavigationStart){
      this.loading= true;
  }
  if(routerEvent instanceof NavigationEnd||
      routerEvent instanceof NavigationError ||
      routerEvent instanceof NavigationCancel
      ){
        this.loading= false;
    }
  }
  doNothing()
  {
    return;
  }

  private setvalues(app: any,connect: any,install: any,cookie:any) {
    this.title =app['TITLE'];
    this.YES =app['YES'];
    this.NO =app['NO'];
    this.titleService.setTitle(app['TITLE']);
    this.connected=connect['connected'];
    this.disconnected=connect['disconnected'];
    this.online=connect['online'];
    this.offline=connect['offline'];
    this.installnow =install['installnow'];
    this.updateavailable =install['updateavailable'];
    this.currentversion =install['CURRENTVERSION'];
    this.newversion =install['NEWVERSION'];

    this.DONTASK =install['DONTASK'];

    this.messageTap2Ipad =install['tap3'];

    this.message ={key: 'install', sticky: true, severity:install['tap1'],
    summary:install['summary'],
    detail:install['detail'],
    data:install['tap2']
   };
   if(!this.isIOS){
    this.title =app['TITLE'];
    this.titleService.setTitle(app['TITLE']);
    }
    let logourl = `https://${environment.domain}/assets/img/logo.jpg`;
    const dn = new Date();
    // adjust 0 before single digit date
    let date = ("0" + dn.getDate()).slice(-2);
    let month = ("0" + (dn.getMonth() + 1)).slice(-2);
    let year = dn.getFullYear();

       this.metaService.addTags(
         [
           {name: 'viewport', content: 'width=device-width, initial-scale=1'},
           {name: 'description', content:this.title},
           {name: 'theme-color', content:'#B41313'},
           {name: 'robots', content: 'INDEX, FOLLOW'},
           {name: 'author', content: 'Meteo Beverwijk'},
           {name: 'publisher', content: 'Filmfest Ballmertshofen, Germany'},
           {name: 'keywords', content: 'Ballmertshofer Filmfest,Filmfest,Filmfestkuh,Bob Dylan,Ballmertshofen'},
           {name: 'apple-mobile-web-app-capable', content:'yes'},
           {name: 'apple-mobile-web-app-status-bar-style', content:'translucent'},
           {name: 'msapplication-TileColor', content:'#ffffff'},
           {name: 'msapplication-TileImage', content:'/ms-icon-144x144.png'},
           {name: 'msapplication-config', content:'/browserconfig.xml'},
           {httpEquiv: 'Content-Type', content: 'text/html'},
           {property: 'og:title',content:this.title},
           {property: 'og:description', content:this.title},
           {property: 'og:url', content:`https://${environment.domain}`},
           {property: 'og:image', content: logourl},
           {property: 'og:image:secure_url', content: logourl},
           {property: 'og:image:url', content:logourl},
           {property: 'og:image:type', content:'image/jpeg'},
           {property: 'og:type', content: "website"},
           {content:app['TITLE']},
           {name: 'date', content: `${year}-${month}-${date}`, scheme: 'YYYY-MM-DD'},
           {charset: 'UTF-8'}
         ]);

     this.ccService.getConfig().content = this.ccService.getConfig().content || {};
     // Override default messages with the translated ones
       const ccconfig = this.ccService.getConfig();
       if(ccconfig&& ccconfig.content)
        { ccconfig.content.header = cookie['header'];
         ccconfig.content.message = cookie['message'];
         ccconfig.content.dismiss = cookie['dismiss'];
         ccconfig.content.allow = cookie['allow'];
         ccconfig.content.deny = cookie['deny'];
         ccconfig.content.link = cookie['link'];
         ccconfig.content.policy = cookie['policy'];
       }
     this.ccService.destroy(); //remove previous cookie bar (with default messages)
     this.ccService.init(this.ccService.getConfig());
    //  if(!this.first && environment.production)
    //  {
    //    this.showInstallForiOS();
    //  }
  }

  private GetLanguageText() {

    const data = this.translateService.instant('APP');
    const connect = this.translateService.instant('CONNECT');
    const install = this.translateService.instant('INSTALL');
    const cookie = this.translateService.instant('cookie');
    this.setvalues(data,connect,install,cookie);

    // this.translateService
    //   .get(['APP.TITLE' , 'cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
    //   .subscribe(data => {

    //    let logourl = `https://${environment.domain}/assets/img/logo.jpg`;
    //    const dn = new Date();
    //    // adjust 0 before single digit date
    //    let date = ("0" + dn.getDate()).slice(-2);
    //    let month = ("0" + (dn.getMonth() + 1)).slice(-2);
    //    let year = dn.getFullYear();


    //       this.metaService.addTags(
    //         [
    //           {name: 'viewport', content: 'width=device-width, initial-scale=1'},
    //           {name: 'description', content:this.title},
    //           {name: 'theme-color', content:'#B41313'},
    //           {name: 'robots', content: 'INDEX, FOLLOW'},
    //           {name: 'author', content: 'Meteo Beverwijk'},
    //           {name: 'publisher', content: 'Filmfest Ballmertshofen, Germany'},
    //           {name: 'keywords', content: 'Ballmertshofer Filmfest,Filmfest,Filmfestkuh,Bob Dylan,Ballmertshofen'},
    //           {name: 'apple-mobile-web-app-capable', content:'yes'},
    //           {name: 'apple-mobile-web-app-status-bar-style', content:'translucent'},
    //           {name: 'msapplication-TileColor', content:'#ffffff'},
    //           {name: 'msapplication-TileImage', content:'/ms-icon-144x144.png'},
    //           {name: 'msapplication-config', content:'/browserconfig.xml'},
    //           {httpEquiv: 'Content-Type', content: 'text/html'},
    //           {property: 'og:title',content:this.title},
    //           {property: 'og:description', content:this.title},
    //           {property: 'og:url', content:`https://${environment.domain}`},
    //           {property: 'og:image', content: logourl},
    //           {property: 'og:image:secure_url', content: logourl},
    //           {property: 'og:image:url', content:logourl},
    //           {property: 'og:image:type', content:'image/jpeg'},
    //           {property: 'og:type', content: "website"},
    //           {content:data['APP.TITLE']},
    //           {name: 'date', content: `${year}-${month}-${date}`, scheme: 'YYYY-MM-DD'},
    //           {charset: 'UTF-8'}
    //         ]);


    //     this.ccService.getConfig().content = this.ccService.getConfig().content || {};
    //     // Override default messages with the translated ones

    //       const ccconfig = this.ccService.getConfig();
    //       if(ccconfig&& ccconfig.content)
    //        { ccconfig.content.header = data['cookie.header'];
    //         ccconfig.content.message = data['cookie.message'];
    //         ccconfig.content.dismiss = data['cookie.dismiss'];
    //         ccconfig.content.allow = data['cookie.allow'];
    //         ccconfig.content.deny = data['cookie.deny'];
    //         ccconfig.content.link = data['cookie.link'];
    //         ccconfig.content.policy = data['cookie.policy'];
    //       }
    //     this.ccService.destroy(); //remove previous cookie bar (with default messages)
    //     this.ccService.init(this.ccService.getConfig());
    //   });
  }
  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }
  ngOnDestroy() {
  // unsubscribe to cookieconsent observables to prevent memory leaks
  this.popupOpenSubscription.unsubscribe();
  this.popupCloseSubscription.unsubscribe();
  this.initializeSubscription.unsubscribe();
  this.statusChangeSubscription.unsubscribe();
  this.revokeChoiceSubscription.unsubscribe();
  this.noCookieLawSubscription.unsubscribe();
  this.renderer.removeClass(document.body, this.bgclass);
  }
}
