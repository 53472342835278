
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NEVER,Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from './loader/loader.service';
import { Network } from '@ngx-pwa/offline';
import { MessageService } from 'primeng/api';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthError } from '@azure/msal-browser';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  STATUS504!:string;
  STATUS409!:string;
  STATUS500_1!:string;
  STATUS500_2!:string;
  constructor(private _router:Router,
    private _messageService: MessageService,
     public _snackBar: MatSnackBar,
     private _translate:TranslateService,
     private network: Network,
     private _loaderService: LoaderService,
     ){
      const status=  this._translate.instant('HTTPSTATUS');
      this.setvalues(status)
     }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // install an error handler
        return next.handle(req).pipe(
          catchError((err: HttpErrorResponse) => {
            if(!this.network.online)
            {
              return next.handle(req);
            }
            if (err.error instanceof Error) {
                // A client-side or network error occurred. Handle it accordingly.
                // this.logService.error('An error occurred:', err.error.message);
            } else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                if (err instanceof HttpErrorResponse) {
                  this._loaderService.hide();
                  if(!this.network.online)
                  {
                    return next.handle(req);
                  }
                  if (err.error instanceof AuthError) {
                    // A client-side or network error occurred. Handle it accordingly.
                    //this.logService.error('An error occurred:', err.error.message);
                  }
                  else if (err.error instanceof Error) {
                    // A client-side or network error occurred. Handle it accordingly.
                   // this.logService.error('An error occurred:', err.error.message);
                  }
                  else if (err.status === 504) {
                    let snackBarRef = this._snackBar.open('Application interface (API) is niet beschikbaar. Heb je wel een internetverbinding? Als het antwoord Ja is dan is de API niet beschikbaar. Gegevens kunnen niet vernieuwd worden.', 'X');
                    snackBarRef.onAction().subscribe(() => {
                        snackBarRef.dismiss();
                    });
                    return NEVER;
                  }
                  else if (err.status === 403) {
                    let snackBarRef = this._snackBar.open(`Je hebt niet voldoende rechten om gevens te benaderen via deze url. ${err.message}`, 'X');
                    snackBarRef.onAction().subscribe(() => {
                        snackBarRef.dismiss();
                    });
                    return NEVER;
                  }

                  if (err.status === 401) {
                    if(err.url && err.url.indexOf('api')!==-1){
                      console.log("get refesh toke");

                    }
                   this._router.navigate(['login']);
                  }
                  else if (err.status === 404 && err.url &&  err.url.indexOf('api')!==-1) {
                    return NEVER;
                  }
                  else if (err.status === 404&& err.url &&   err.url.indexOf('api')==-1) {
                    this._router.navigate(['404']);
                  }
                  else if  (err.status === 409) {
                    if(err.url && err.url.indexOf('subscriber')!==-1)
                    {
                    this._messageService.clear();
                    this._messageService.add({key:"center" ,severity:'warn', summary: 'Push berichten', detail:'Je hebt je al aangemeld'});
                    }
                    return NEVER;
                  }
                  else if (err.status === 500) {
                    //this.logService.error(`${this.STATUS500_1} ${err.status}, ${this.STATUS500_2}: ${err.error}`, err.error.message);
                  }
                  else if (err.status === 504) {

                    this._messageService.clear();
                    this._messageService.add({key:"center" ,severity:'warn', summary: 'Error', detail:this.STATUS504});

                  }
                }
            }
            let errormessage:string='';
            let errortype:string='lezen uit';

            if( req.url.indexOf('-api-')!=-1)
            {
              switch (req.method) {
                case 'PATCH':
                  errortype='bijwerken in'
                  break;
                case 'DELETE':
                  errortype='verwijderen uit'
                  break;
                default:
                  break;
              }
              errormessage = `Kan de gevens niet ${errortype} de database.`;
            }
            this._messageService.add({key: 'saveupdate', sticky: true, severity:'error', summary:`Opps.`, detail:`Opps, er is is mis gegaan.\n\n\n${errormessage}\n\n\nURL:\n${req.url} \n\nType:\n${req.method}`});
            return NEVER;
        })
        )
    }
    private setvalues(status: any) {
      this.STATUS409= status['504'];
      this.STATUS504= status['409'];
      this.STATUS500_1= status['500_1'];
      this.STATUS500_2= status['500_2'];
    }
}
