export const environment = {
  production: true,
  domain:'filmfestkruh.de',
  domainapi:'filmfestkruh.de',
  domainapiAppPath:'/api',
  apiVersion:'1.0',
  signalrHubUrl:'/datapipe',
  appInsights: {
    instrumentationKey: 'a23bdfc7-d139-4344-aafc-a6b500a056eb'
  },
  config: {
      VAPID_PUBLIC_KEY:'BB0qjr-L9Vs4UtaLy9cXqj8XII3bPuOHue9hOA13vyyQwJoHwzErGEVk6QwG41WLLS2K1EzmTRTeOWSvqhsj9q0',
      apiEndpoint:'https://johan0251.onmicrosoft.com/dbc81158-6b90-4b67-b4a4-b36666565706',
      apiIMDB:"https://tv-api.com/API/",
      IMDB_KEY:'k_fuwbb7p7'
  },
  imdb:{
    apiMovieEndpoint:"https://tv-api.com/de/API/SearchMovie/k_fuwbb7p7/",
    apiTitleEndpoint:"https://tv-api.com/de/API/Title/k_fuwbb7p7/"
  },
  regex:{
      urlfilter: '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$',
      titlefilter: '^.{3,2048}$',
    imgtype:'_(?<t>[L|M|S])[W|H]\\d{3,4}_',
    imgdir:'\/((?<l>imgl)|(?<s>imgs)|(?<m>imgm)|(?<t>thumbnails)|(?<i>images))\/',
    youtubeUrlfilter:'(?:https?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtube|youtu|youtube-nocookie)\.(?:com|be)\/(?:watch\?v=|watch\?.+&v=|embed\/|v\/|.+\?v=)?([^&=\n%\?]{11})',
    youtubeIdfilter:'([^"&?\\/ ]{11})'
  },
  blobimgm:'https://filmfestkruh.blob.core.windows.net/imgm/',
  blobimgs:'https://filmfestkruh.blob.core.windows.net/imgs/',
  blobimgl:'https://filmfestkruh.blob.core.windows.net/imgl/',
  blobthumb:'https://filmfestkruh.blob.core.windows.net/thumbnails/',
   serviceWorkerScript: '/sw-master.js',
   auth: {
    clientId: "6e01b5dc-ab10-4cd0-913b-3d21e9f27b68",
    authority: "https://login.microsoftonline.com/f81f94de-90a0-4b51-b087-5573a4cb195c",
    validateAuthority: true,
    redirectUri: "/dashboard",
    navigateToLoginRequestUrl: true,
    tenantId:"f81f94de-90a0-4b51-b087-5573a4cb195c"
  },
  cache: {
    cacheLocation: "localStorage"
  },
  system: {
    loadFrameTimeout: 10000,
  },
  scopes: {
    loginRequest: [ "openid", "profile" ]
  },
  resources: {
    filmfestkuhApi: {
      resourceWs: "wss://filmfestkruh.de/api",
      resourceUri: "https://filmfestkruh.de/api/v1",
      resourceScope: "https://johan0251.onmicrosoft.com/dbc81158-6b90-4b67-b4a4-b36666565706/user_impersonation"
    }
  },
  groups: {
    groupAdmin: "e36dcffd-abf9-47bf-80d6-4597fbfb63bd",
    groupTester: "9f63beca-867c-4538-bd43-4f64a5bcd6e4",
    groupInvoerder: "0e0d2ae1-78cb-40f9-82b0-b3fc3494d689"
  },
  roles: {
    roleAdmin: "Admin",
    roleInvoerder: "Invoerder"
  }
};
