import { Component, OnInit } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'kruh-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {
  title!:string;
  intro!:string;
  whatarecookies!:string;
  usecookies!:string;
  othercookies!:string;
  controlcookies!:string;
  cookiecontent1!:string;
  cookiecontent2!:string;
  cookiecontent3!:string;
  cookiecontent4!:string;
  constructor( private _translate:TranslateService) { }

  ngOnInit() {
    this.Getdata();
    this._translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
      const cp = event.translations["CP"];
      this.setvalues(cp);
    });
  }

  private setvalues(data: any) {
    this.title = data['title'];
    this.intro = data['intro'];
    this.whatarecookies = data['whatarecookies'];
    this.usecookies = data['usecookies'];
    this.othercookies = data['othercookies'];
    this.controlcookies = data['controlcookies'];
    this.cookiecontent1 = data['cookiecontent1'];
    this.cookiecontent2 = data['cookiecontent2'];
    this.cookiecontent3 = data['cookiecontent3'];
    this.cookiecontent4 = data['cookiecontent4'];
  }

  private Getdata() {
    const cp = this._translate.instant('CP');
    this.setvalues(cp);
  }
}
