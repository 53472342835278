import {CardModule} from 'primeng/card';
import {GalleriaModule} from 'primeng/galleria';
import {MenubarModule} from 'primeng/menubar';
import {DropdownModule} from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {CheckboxModule} from 'primeng/checkbox';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {SpinnerModule} from 'primeng/spinner';
import {KeyFilterModule} from 'primeng/keyfilter';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {TooltipModule} from 'primeng/tooltip';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { NgModule } from '@angular/core';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms';
import {ToastModule} from 'primeng/toast';
import {OrderListModule} from 'primeng/orderlist';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {FieldsetModule} from 'primeng/fieldset';
import {MegaMenuModule} from 'primeng/megamenu';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {AccordionModule} from 'primeng/accordion';
import { CarouselModule } from 'primeng/carousel';
const modules = [
  OverlayPanelModule,
  TooltipModule,
  CardModule,
  GalleriaModule,
  MenubarModule,
  DropdownModule,
  AutoCompleteModule,
  ButtonModule,
  InputTextModule,
  InputTextareaModule,
  CheckboxModule,
  SpinnerModule,
  KeyFilterModule,
  MessagesModule,
  MessageModule,
  FormsModule,
  ReactiveFormsModule,
  ToastModule,
  OrderListModule,
  ProgressSpinnerModule,
  DynamicDialogModule,
  FieldsetModule,
  MegaMenuModule,
  TieredMenuModule,  
  CarouselModule,
  AccordionModule,
  
];

@NgModule({
  imports: [modules],
  exports: [ modules]
})
export class PrimeNGModule { }
