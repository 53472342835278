import { HttpClient } from '@angular/common/http';
import { ResourceService } from './resource.service';
import { ConfigService } from '../../config.service';
import { Injectable } from '@angular/core';
import { AlertService } from '../alert.service';
import { ILink } from '../../model/link.interface';
@Injectable()
export class LinkService extends ResourceService<ILink> {
  constructor(httpClient: HttpClient,
    _alertServices:AlertService,
    configService: ConfigService
    ) {
      super(
        httpClient,
        configService.apiAzure,
        _alertServices,
        'link'
        );
  }
}
