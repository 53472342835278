import { Component,OnInit } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { Dropdown } from 'primeng/dropdown';
import { IDropdown } from 'src/app/core/model/dropdown.interface';
import { IPhoto } from 'src/app/core/model/photo';
import { IPhotoset, IPhotosets, Photoset } from 'src/app/core/model/photoset.interface';
import { PhotoService } from 'src/app/core/services/photo/photo.services';
import { PhotoDetailComponent } from '../photo-details/photo-detail.component';
import { DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.css']
})
export class PhotosComponent implements OnInit {
  photos: IPhoto[]=[];
  photossets: IPhotoset[]=[];
  title: string='Fotos';
  setList: IDropdown[]=[];
  type: string='72177720319271879';
  PHSELECTALBUM: any;
  CLOSE: string="close";

  constructor(
    private _photoService: PhotoService,
    public _dialogService: DialogService,
    private  _translateService: TranslateService) {

      this._translateService.onLangChange.subscribe((event: TranslationChangeEvent) => {
        const app = event.translations['APP'];
        const photo = event.translations['PHOTO'];
        this.setvalues(app, photo);
      });
   }

  ngOnInit() {
    this.GetLanguageData()
    this.getPhotosSets();
    this.getPhotos();
  }

  getPhotos(): void {
    this._photoService.getPhotos(this.type)
      .then((data:Photoset) => {
        this.title=`${data.title} #${data.total}`
        data.photo.forEach(element => {
          const url:string=`https://live.staticflickr.com/${element.server}/${element.id}_${element.secret}_m.jpg`;
          const p:IPhoto= { title: element.title,link: url}
          this.photos.push(p);
        });
      });
  }
  getPhotosSets(): void {
    this._photoService.getPhotosSets()
      .then((data:IPhotosets) => {
        data.photoset.forEach(element => {
          const p:IDropdown= { name: element.title._content,code: element.id}
          this.setList.push(p);
        });
      });
  }

  public onChangeDropdown( event:Dropdown ) {
    this.type=event.value.code;

    this.photos=[];
    this.getPhotos();
  }
  public showdetail(event:any)
  {
    const dialogRef = this._dialogService.open(PhotoDetailComponent, {
      width: '10%',
      height:'10%',
      header: this.title,
      data: {
        id:  event,
        photos:this.photos
      },
      closable:true,
      closeOnEscape:true
    });
  }

  private setvalues(app: any, photo:any) {
    this.PHSELECTALBUM = photo['PHSELECTALBUM'];
    this.CLOSE = app['CLOSE'];
  }

  private GetLanguageData() {
    const data = this._translateService.instant('APP');
    const photo = this._translateService.instant('PHOTO');
    this.setvalues(data,photo);
  }
    encodeURL2(num:number)
    {if(typeof num!=='number')num=parseInt(num);var enc='', alpha='123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ'; var div=num,mod;while(num>=58){div=num/58;mod=num-(58*Math.floor(div));enc=''+alpha.substr(mod,1) +enc;num=Math.floor(div);} return(div)?''+alpha.substr(div,1)+enc:enc;}

}