import { NgModule , Optional, SkipSelf, OnDestroy} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ConfigService } from './config.service';
import { throwIfAlreadyLoaded } from './module-import-guard';

import { SharedModule } from '../shared/shared.module';
import { AlertService } from './services/alert.service';

import { AuthService } from './services/auth.services';
import { PushService } from './push.service';
import { AppInsightsService } from './services/app-insights.service';
import { PolicyComponent } from '../components/info/policy.component';
import { ApiTokenService } from './services/apitoken/api-token.service';
import { LanguageService } from './services/language.service';
import { LinkService } from './services/api/link.services';
import { ContentDataService } from './services/api/editorial.service';
import { YearService } from './services/year.service';
import { LoaderComponent } from './loader/loader.component';
import { ProgramDataService } from './services/api/program.service';
import { ProgramgroupDataService } from './services/api/programgroup.services';
import { GenreService } from './services/gerne.services';
import { PriceService } from './services/price.service';
import { CountryService } from './services/country.services';
import { EditionService } from './services/api/edition.service';
import { WebPushNotificationService } from './services/api/webpush-notification.services';
import { WebPushService } from './services/api/webpush-subscription.services';
import { FileService } from './services/api/file.service';
import { VideoService } from './services/api/video.services';
import { VideoTypeService } from './services/videotype.services';

// TODO: Add Angular decorator.
@NgModule({
  imports: [
    CommonModule,
    RouterModule,// we use router-outlet and routerLink
    SharedModule,
  ],
  exports: [
    LoaderComponent
  ],
  declarations: [
    LoaderComponent,
    PolicyComponent
    ],
  providers: [
    LanguageService,
    YearService,
    GenreService,
    PriceService,
    CountryService,
    VideoTypeService,
    FileService,
    AlertService,
    ConfigService,
     PushService,
     AuthService,
     ApiTokenService,
     AppInsightsService,
     LinkService,
     ContentDataService,
     EditionService,
     ProgramDataService,
     VideoService,
     ProgramgroupDataService,
     WebPushNotificationService,
     WebPushService
  ]
})
export class CoreModule implements OnDestroy {
  ngOnDestroy(): void {
   console.log("Opruimen Core");
  }
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
   throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
 }
