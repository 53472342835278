import { NgModule } from '@angular/core';
import { Routes, RouterModule ,PreloadAllModules} from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found.component';
import { LogoutComponent } from './components/authorization/logout/logout.component';
import { LoginComponent } from './components/authorization/login/login.component';
import { LinksComponent } from './components/info/link/links.component';
import { PolicyComponent } from './components/info/policy.component';
import { NoWebpushComponent } from './components/info/no-webpush/no-webpush.component';
import { environment } from 'src/environments/environment';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FailedComponent } from './components/failed/failed.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  // { path: '', redirectTo: 'link', pathMatch: 'full'},
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'nl', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'en', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'de', redirectTo: 'dashboard', pathMatch: 'full' },
  {path: 'dashboard', component: DashboardComponent},
  {path: 'dashboard/:tabindex', component: DashboardComponent },
  {path: 'login', component: LoginComponent},
   {path: 'link', component: LinksComponent },
   {path: 'info/policy', component: PolicyComponent},
   {
    path: 'management',
    loadChildren: () => import('./management/management.module').then(m => m.ManagmentModule),
    data: { preload: false, delay: false }
    ,canActivate: [MsalGuard]
  },
  {
    path: 'controls',
    data: { preload: false, delay: true },
    loadChildren: () => import('./controls/controls.module').then(m => m.ControlsModule)
  },
  { path: 'login-failed', component: FailedComponent},
   { path:'nowebpush', component:NoWebpushComponent},
   { path: 'logout', component: LogoutComponent },
   {path: 'login', component: LoginComponent},
   { path: '404', component: PageNotFoundComponent},
   { path: '**', pathMatch: 'full', component: PageNotFoundComponent}
   ];


@NgModule({
  imports: [RouterModule.forRoot(routes,
   { preloadingStrategy:PreloadAllModules,enableTracing:
    !environment.production
    }
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
