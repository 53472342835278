import {  Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root' // just before your class
})
export class SpreadShopJSLoader {


  constructor(
      
    ) { 

    }
  private getNativeWindow(): any {
    return window;
  }
  private getNativeDocument(): any {
    return document;
  }

  load(callbackName: string): Promise<void> {
    return new Promise<void>((resolve: Function, reject: Function) => {
      const script = this.getNativeDocument().createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.src = 'https://shop.spreadshirt.de/shopfiles/shopclient/shopclient.nocache.js';
      this.getNativeWindow()[callbackName] = () => {
        resolve();
      };
      script.onerror = (error: Event) => {
        reject(error);
      };
      this.getNativeDocument().body.appendChild(script);
     
    });
  }
}