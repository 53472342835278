import {Injectable} from '@angular/core';
import { IDropdown } from '../model/dropdown.interface';

@Injectable()
export  class PriceService {
  constructor() {}
  getPrice(): IDropdown[] {
    return [
      { name: '?', code: '0' },
      { name: '1,-', code: '2' },
      { name: '2,-', code: '6' },
      { name: '2,5', code: '5' },
      { name: '5,-', code: '7' },
      { name: '3,-', code: '8' },
      { name: '3.5', code: '9' },
      { name: '4,-', code: '10' },
      { name: '6,-', code: '11' },
      { name: '7,-', code: '12' },
      { name: '8,-', code: '13' },
      { name: '0,-', code: '14' },
      { name: '4,5', code: '15' },
      { name: '6,5', code: '16' },
      { name: '7,5', code: '17' },
      { name: '8,5', code: '17' },
      { name: '9,-', code: '18' },
      { name: '9,5', code: '19' },
      { name: '10', code: '20' },
      { name: '10,5', code: '21' },
      { name: '11,-', code: '22' },
      { name: '11,5', code: '23' },
      { name: '12,-', code: '24' },
      { name: '12,5', code: '25' }
    ];
  }
}