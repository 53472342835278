import { Injectable} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { IApiState } from './Iapistate';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '../../jwthelper.services';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class ApiTokenService {

  private apiSubject = new Subject<IApiState>();
  private apitokenSubject = new Subject<IApiState>();
  apiRole$:Observable<IApiState>;
  apiAsToken$:Observable<IApiState>;
  endpoint!:string;
  roles: string[]=[];
  token!: string;
  userData: any;
  userRoleData: any;

  constructor(
     private _authService: MsalService,
    private _router: Router,
    private _jwthelper:JwtHelperService,
  ) {
      this.apiRole$ = this.apiSubject.asObservable();
      this.apiAsToken$ = this.apitokenSubject.asObservable();
  }

  // Ophalen van api token  en opslaan na succes volle inlog en daarna de rol bepalen
  getApiToken(){

      this.getRoleFromApiToken();
      this._router.navigate(['dashboard']);
  }

  getRoleFromApiToken() {
    const scopes = [environment.resources.filmfestkuhApi.resourceScope];
    const account = this._authService.instance.getAllAccounts().filter( f=> { return f.tenantId==environment.auth.tenantId})[0];
    this._authService.acquireTokenSilent({scopes, account}).toPromise().then((response: any): void  => {
      this.token= response.accessToken;
            }).finally( ()=>{
              let apiState:IApiState={
                isAdmin:false,
                hasToken:false,
                isInvoerder: false,
                isTester: false
              };
              if(this.token==undefined)
              {
                this.apiSubject.next(apiState);
              }
              else if(this._jwthelper.getGroups(this.token))
                {
                  this.userData = <string[]><unknown>this._jwthelper.getGroups(this.token);
                  const expectedGroup = [environment.groups.groupAdmin,environment.groups.groupInvoerder];
                  if(this.testGroup(expectedGroup)) {
                    apiState.hasToken=true;
                    this.apitokenSubject.next(apiState);
                  }
                  if(this.testGroup([environment.groups.groupAdmin]))
                  {
                    apiState.isAdmin=true;
                    this.apitokenSubject.next(apiState);
                  }
                  else
                  {
                    apiState.isInvoerder=true;
                    this.apitokenSubject.next(apiState);
                  }
                }
                this.apiSubject.next(apiState);
            }
          );
      }
      private testGroup(expectedGroup: any):boolean {
        let hasGroup = false;

        expectedGroup.forEach((element:any) => {
          if(!hasGroup)
          {hasGroup =this.userData.includes(element);}
        });
        return hasGroup;
      }

      private testRole(expectedRole: any):boolean {
        if(expectedRole==null)
        {
          return false;
        }
        let hasRole = false;

        expectedRole.forEach((element:any) => {
          if(!hasRole)
          {hasRole =this.userRoleData.includes(element);}
        });
        return hasRole;
      }
}
