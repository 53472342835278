import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'groupByDate'})
export class GroupByPipe implements PipeTransform {
transform(collection: Array<any>, property: string = 'date'): Array<any> {
    if(!collection) {
        return [];
    }
    const gc = collection.reduce((previous, current)=> {
        if(!previous[current[property]]) {
            previous[current[property]] = [];
        }
        console.log(current)
            current.progaminteranlarray.forEach((x:any) => previous[current[property]].push(x));
        return previous;
    }, {});
    return Object.keys(gc).map(date => ({ date: date, progaminteranlarray: gc[date] }));
    }
}
