import { Injectable } from '@angular/core';

function _window(): Window {
    // return the native window obj
    return window;
}

@Injectable()
export class WindowRef {

    get nativeWindow(): Window {
        return _window();
    }
        //StandAlone mode
        get standalone(): boolean {
            let standalone = (this.nativeWindow.navigator as any).standalone;
            if (this.nativeWindow.matchMedia('(display-mode: standalone)').matches) {
              standalone = false;
            }
            return standalone;
        }

}