import {Injectable} from '@angular/core';
import { IDropdown } from '../model/dropdown.interface';

@Injectable()
export  class VideoTypeService {
  constructor() {}
  getCountries(lcid:string): IDropdown[] {


    if(lcid=="de")
    return [
     
      { name: 'Zeitraffer', code: '1' },
      { name: 'Konzert', code: '2' },
      { name: 'Essen', code: '3' },
      { name: 'General', code: '4' },
      { name: 'Sport', code: '5' },
      { name: 'Theke', code: '6' },
      { name: 'Kneipplauf', code: '7' },
      { name: 'Campingplatz', code: '8' },
      { name: 'Yoga', code: '9' }

      
    ];
    else if(lcid=="nl")
    return [
     
      { name: 'Timeleaps', code: '1' },
      { name: 'Concert', code: '2' },
      { name: 'Eten', code: '3' },
      { name: 'Algemeen', code: '4' },
      { name: 'Sport', code: '5' }, 
      { name: 'Bar', code: '6' },
      { name: 'Kneipplauf', code: '7' },
      { name: 'Camping', code: '8' },
      { name: 'Yoga', code: '9' }


      
    ];
    else
    return [
     
      { name: 'Timeleaps', code: '1' },
      { name: 'Concert', code: '2' },
      { name: 'Essen', code: '3' },
      { name: 'General', code: '4' },
      { name: 'Sport', code: '5' },
      { name: 'Bar', code: '6' },
      { name: 'Kneipplauf', code: '7' },
      { name: 'Camping', code: '8' },
      { name: 'Yoga', code: '9' }

      
    ];
  }
}