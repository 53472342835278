import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms';
import { DatumconvertPipe } from './datumconvert.pipe';


import { PrimeNGModule } from './primeng.modules';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoaderService } from '../core/loader/loader.service';
import { NoWebpushComponent } from '../components/info/no-webpush/no-webpush.component';
import { MaterialModules } from '../modules/material/material.module';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faLock, faBell, faPause,faPlay,faBellSlash, faAt,faEnvelope, faStop, faSearch, faInfo } from '@fortawesome/free-solid-svg-icons';
import { DialogService } from 'primeng/dynamicdialog';
import { YoutubePlayerComponent } from '../components/youtubeplayer/youtube-player.component';
import { GroupByPipe } from '../helper/groupby-pipe';
import { PreventDoubleClickDirective } from '../directive/preventdouble-click.directive';
import { PreventDoubleSubmitDirective } from '../directive/preventdouble-submit.directive';
import { YoutubeApiService } from '../core/services/youtube.service';
import { VimeoPlayerComponent } from '../components/videos/vimeoplayer/vimeo-player.component';
import { ScrollDirective } from '../directive/srcoll.directive';
import { IphonePushComponent } from '../components/info/iphone-push/iphone-push.component';


const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
 PrimeNGModule,
 FlexLayoutModule,
 FontAwesomeModule
];

@NgModule({
  imports: [modules,MaterialModules],
  exports: [ modules,
    DatumconvertPipe,
    GroupByPipe,
    // LoaderComponent,
    MaterialModules,
    YoutubePlayerComponent,
    VimeoPlayerComponent,
     PreventDoubleClickDirective, PreventDoubleSubmitDirective,IphonePushComponent,
     ScrollDirective

  ],
  declarations: [
   DatumconvertPipe,
   GroupByPipe,
   IphonePushComponent,
   NoWebpushComponent,
   YoutubePlayerComponent,
   VimeoPlayerComponent,
   PreventDoubleClickDirective,
   PreventDoubleSubmitDirective,
   ScrollDirective

  ],
  providers:[DatumconvertPipe,DialogService,LoaderService,GroupByPipe, YoutubeApiService
  ],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
      library.addIcons(faEnvelope,faPause,faStop,faPlay, faSearch,faAt,faInfo,faBell,faBellSlash,faLock);
    }
 }
