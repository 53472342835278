export class FindIndexBy{
    
    public static Id(id: number,results:any[]): number {
        let index = -1;
        for (let i = 0; i < results.length; i++) {
            if (results[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
      }

      public static GroupId(id: number,results:any[]): number {
        let index = -1;
        for (let i = 0; i < results.length; i++) {
            if (results[i].group_id === id) {
                index = i;
                break;
            }
        }
        return index;
      }
  }