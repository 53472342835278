import { Component, OnInit } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IPhoto } from 'src/app/core/model/photo';

@Component({
  selector: 'kruh-photo-detail',
  templateUrl: './photo-detail.component.html',
  styleUrls: ['./photo-detail.component.css']
})
export class PhotoDetailComponent implements OnInit {
  photo!: IPhoto;
  index: number=0;
  txtnext: string='next picture';
  txtprevious: string='previous picture';
  CLOSE: string="close";

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private  _translateService: TranslateService
  ) {
    this._translateService.onLangChange.subscribe((event: TranslationChangeEvent) => {
      const app =  event.translations['APP'];
      const photo = event.translations['PHOTO'];
      this.setvalues(app,photo);
    });
  }
  close()
  {
    this.ref.close()
  }

  next(): void {
    if(this.index < this.config.data.photos.length-1)
    {
      ++this.index;
      this.photo =this.config.data.photos[ this.index];
    }
  }
  previous(): void {

    if(this.index >  0 && this.index < this.config.data.photos.length)
    {
      --this.index;
      this.photo =this.config.data.photos[ this.index];
    }
  }

  ngOnInit(): void {
    this.GetLanguageData()
  this.index =this.config.data.id;
    if(this.config.data.id!==undefined)
    {
      this.photo =this.config.data.photos[ this.config.data.id ];
    }
  }

  private setvalues(app: any, photo:any) {
    this.txtnext = photo['NEXT'];
    this.txtprevious = photo['PREVIOUS'];
    this.CLOSE = app['CLOSE'];
  }

  private GetLanguageData() {
    const app = this._translateService.instant('APP');
    const photo = this._translateService.instant('PHOTO');
    this.setvalues(app,photo);
  }
}
