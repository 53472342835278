import { animate, query, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { IDropdown } from 'src/app/core/model/dropdown.interface';
import { IVideo } from 'src/app/core/model/video.interface';
import { VideoService } from 'src/app/core/services/api/video.services';
import { QueryOptions } from 'src/app/core/services/query.options';
import { YearService } from 'src/app/core/services/year.service';
import { Direction } from 'src/app/helper/enum/direction.enum';
import { VisibilityState } from 'src/app/helper/enum/VisibilityState.enum';
import { Filter } from 'src/app/helper/filter';
import { SortData } from 'src/app/helper/sort-data-by-name';

@Component({
  selector: 'kruh-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css'],
  animations: [
    trigger('toggle', [
      state(
        VisibilityState.Hidden,
        style({  height: '0px', transform: 'translateY(-100%)',opacity: 0 })
      ),
      state(
        VisibilityState.Visible,
        style({  height: '61px',transform: 'translateY(0)',opacity: 1 })
      ),
      transition('* => *', [
        query(':self', [
          animate('250ms ease')
        ])
      ])
      ])
    ]
})
export class VideoComponent implements OnInit {
  title: string='Videos';
  videoIds:number[]=[
    351671787,
    351672327,
    351607755,
    351609040,
    351609388,
    343795330,
    343795960,
    343796094,
    343796184,
    343795183,
    343794327,
    222793593,
    177198982,
    177198731,
    177197687,
    177196532,
    177197110,
    177195668,
    177194882,
    177194421,
    177193712,
    177199833,
    177193000,
    177192463,
    177191889,
    175657984,
    47400873,
    102727275,
    102807637,
    103351861,
    103352890,
    103355497,
    103359487,
    71897637,
    71898043,
    71897205,
    71944517,
    71943148,
    72091348,
    47823861,
    102727275,
    102807637,
    103351861,
    103352890,
    103355497,
    103359487,
    343813120,
    343812372,
    343814213,
    343813836,
    343811857,
    343812449,
    343812511,
    343812692,
    343812746,
    343812848,
    343812979,
    343813370,
    343811192,
    343812267,
    343810906,
    343811421,
    343811772,
    343811949,
    557521157,
    850861669,
    850862927];
  videos: number[]=[];
  videoArrays: IVideo[]=[];
  years: IDropdown[]=[];
  results: string[]=[];
  PHSEARCH: any;
  dateNow!: Date;
  year: number=0;
  LTITLE: any;
  SHARE: any;
  PHSELECTYEAR: any;
  form: FormGroup;
  isSearchResult: boolean=false;
  yeardatevent!: number;
  isVisible: boolean=true;
  constructor(
    private _videoservices: VideoService,
    public _yearService: YearService,
    private _translate: TranslateService
    ) {
      this.form = new FormGroup({
        yearName: new FormControl(null),
        titleName: new FormControl(null)
      });
      this.form.get('yearName')?.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged())
        .subscribe(val => {
         if( this.yeardatevent!=+val.name && !this.isSearchResult )
         {
           this.GetContent(+val.name);
           if(this.titleName?.value!=null && this.titleName?.value!='')
           this.form.patchValue({
            titleName:''
           });
          }
        else
        {
          this.isSearchResult=false;
        }
      });
    this.videos = this.videoIds.sort(() => 0.5 - Math.random()).slice(0,6);
    this._translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
      const app = event.translations['APP'];
      const video = event.translations['VIDEO'];
      this.setvalues(app, video);
    });
    this.GetLanguageData();
  }

  ngOnInit(): void {

    this.setYear();
     this.years = this._yearService.getVideoYears().filter(l=>{return +l.name<=this.year });
    this.GetContent(this.year);
  }
  get yearName() {
    return this.form.get('yearName');
  }
  get titleName() {
    return this.form.get('titleName');
  }
  scrollChanged(event:Direction)
  {
      this.isVisible =event==Direction.Up?true:false;
  }
  get toggle(): VisibilityState {
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }

  ACclear()
  {
    this.setYear();
    this.GetContent(this.year);
  }
  // Autocomplete gekozen item
  ACSelect(event:any) {
    const id:number = +event.split('#')[1];
    let o:QueryOptions= new QueryOptions;
    o.id = id;
    this._videoservices.list(o).subscribe( (result:IVideo[]) =>{
      if(result!=null && result.length>0)
      {
        this.videoArrays = SortData.By(result,"changed");
      }
    });
  }

  // T.b.v. autocomplete input
  search(event:any) {
    let query = event.query;

    let qo:QueryOptions= new QueryOptions;
    qo.type="search";
    qo.search=encodeURIComponent(query);
    qo.lcid="de";//this._translate.currentLang;
    this._videoservices.listByPath(qo).subscribe(
      (result:any) =>{
        if(result)
        this.results =Filter.ByValueForACvideo(result,query);
        else this.results =[];
      });
  }

  private setYear() {
    this.dateNow = new Date();
    this.year = this.dateNow.getFullYear();
  }

  private GetContent(year:number) {
    let o:QueryOptions= new QueryOptions;
    o.lcid = this._translate.currentLang;
   // o.id =0;
    o.year =year;

    this._videoservices.list(o).subscribe( (result:IVideo[]) =>{
      if(result)
      {
         this.videoArrays= result;
        const y = this.years.filter(l => { return l.name == result[0].year.toString(); })[0];
        this.form.patchValue({
          yearName: y
        });
      }
    });
  }
  private setvalues(app: any, video:any) {
    this.LTITLE=     app['LTITLE'];
    this.SHARE =     app['SHARE'];
    this.PHSELECTYEAR=     app['PHSELECTYEAR'];
    this.PHSEARCH=     video['PHSEARCH'];
  }

  private GetLanguageData() {
    const data = this._translate.instant('APP');
    const video = this._translate.instant('VIDEO');
    if(typeof data !== 'string' && typeof video !== 'string')
    this.setvalues(data,video);
  }

}
