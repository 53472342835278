import { MsalInterceptorConfiguration, ProtectedResourceScopes } from "@azure/msal-angular";
import { InteractionType } from "@azure/msal-browser";
import { environment } from "src/environments/environment";

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
     const protectedResourceMap = new Map<string,  Array<string | ProtectedResourceScopes> | null>([
      [ environment.resources.filmfestkuhApi.resourceWs  + '/ws*',null],
      [ environment.resources.filmfestkuhApi.resourceWs  + '/datapipe'      ,null],
      [ environment.resources.filmfestkuhApi.resourceUri + '/programgroup'  ,[environment.resources.filmfestkuhApi.resourceScope] ],
      [ environment.resources.filmfestkuhApi.resourceUri + '/webpush/notify',[environment.resources.filmfestkuhApi.resourceScope] ],
      [ environment.resources.filmfestkuhApi.resourceUri + '/webpush/count' ,[environment.resources.filmfestkuhApi.resourceScope] ],
      [ environment.resources.filmfestkuhApi.resourceUri + '/webpush'       ,null],
      [ environment.resources.filmfestkuhApi.resourceUri,[{
              httpMethod: "POST",
              scopes: [ environment.resources.filmfestkuhApi.resourceScope]

          },
          {
              httpMethod: "PATCH",
              scopes: [ environment.resources.filmfestkuhApi.resourceScope]
          },
          {
              httpMethod: "GET",
              scopes: [ ]
          }
          ]
      ] 
    ]);
    // protectedResourceMap.set(
    //   environment.resources.filmfestkuhApi.resourceWs+'/ws',null);

    // protectedResourceMap.set( environment.resources.filmfestkuhApi.resourceWs+'/datapipe',null);
    // protectedResourceMap.set(
    //   environment.resources.filmfestkuhApi.resourceUri,[
    //     {
    //         httpMethod: "POST",
    //         scopes: [ environment.resources.filmfestkuhApi.resourceScope]
    //     },
    //     {
    //         httpMethod: "PATCH",
    //         scopes: [ environment.resources.filmfestkuhApi.resourceScope]
    //     },
    //     {
    //         httpMethod: "GET",
    //         scopes: [ environment.resources.filmfestkuhApi.resourceScope]
    //     }
    //     ]
    //   );

      //  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);


    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap:  protectedResourceMap,
    };
  }