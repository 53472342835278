export interface QueryBuilder {
  toQueryMap: () => Map<string, string>;
  toQueryString: () => string;
}
enum Language {da = 1030,de = 1031,en = 1033, fr=1036, nl=1043, it=1040, no=1044, all=0};


export class QueryOptions implements QueryBuilder {
  public pageNumber: number;
  public pageSize: number;
  public id!: number;
  public lcidnr!: number|null;
  public type!: string;
  public lcid!: string|null;
  public year!: number;
  public fields!:string;
  public search!:string;
  public imdb_id: string|undefined;
  public small!: number;

  constructor() {
    this.pageNumber = 0;
    this.pageSize = 0;
  }

  toQueryMap() {
    const pathMap = new Map<string, string>();

    if(this.lcidnr!=undefined && this.lcidnr!=null)
    {pathMap.set('lcidnr', `${this.lcidnr}`);}
    if(this.lcid!=undefined && this.lcid!=null)
    {
      let lcid_enum: Language = (<any>Language)[this.lcid];
      pathMap.set('lcid', `${lcid_enum}`);
    }
    if(this.id!=undefined && this.id!=null)
    {
      pathMap.set('id', `${this.id}`);
    }
    if(this.imdb_id!=undefined && this.imdb_id!=null)
    {
      pathMap.set('imdb_id', `${this.imdb_id}`);
    }
    if(this.type!=undefined && this.type!=null)
    pathMap.set('type', `${this.type}`);
    if(this.year!=undefined && this.year!=null)
    {pathMap.set('year', `${this.year}`);}
    if(this.fields!=undefined && this.fields!=null)
    pathMap.set('fields', `${this.fields}`);
    if(this.search!=undefined && this.search!=null)
    pathMap.set('search', `${this.search}`);
    if(this.small!=undefined && this.small!=null)
    pathMap.set('small', `${this.small}`);

    return pathMap;
  }
  toPathMap() {
    const queryMap = new Map<string, string>();
    if(this.pageNumber>0)
    queryMap.set('pageNumber', `${this.pageNumber}`);
    if(this.pageSize>0)
    queryMap.set('pageSize', `${this.pageSize}`);
    if(this.type!=undefined && this.type!=null)
    queryMap.set('type', `${this.type}`);
    if(this.fields!=undefined && this.fields!=null)
    queryMap.set('fields', `${this.fields}`);
    if(this.lcid!=undefined && this.lcid!=null)
    {
      var lcid_enum: Language = (<any>Language)[this.lcid];
      queryMap.set('lcid', `${lcid_enum}`);
    }
    if(this.id!=undefined && this.id!=null)
    {
      queryMap.set('id', `${this.id}`);
    }
    if(this.lcidnr!=undefined && this.lcidnr!=null )
    {
      queryMap.set('lcid', `${this.lcidnr}`);
    }
    if(this.type!=undefined && this.type!=null)
    queryMap.set('type', `${this.type}`);
    if(this.search!=undefined && this.search!=null)
    queryMap.set('search', `${this.search}`);
    if(this.year!=undefined && this.year!=null)
    {queryMap.set('year', `${this.year}`);}

    if(this.small!=undefined && this.small!=null)
    queryMap.set('small', `${this.small}`);


    return queryMap;
  }

  toQueryString() {
    let queryString = '?';
    this.toQueryMap().forEach((value: string, key: string) => {
      queryString = queryString.concat(`${key}=${value}&`);
    });

    return queryString.substring(0, queryString.length - 1);
  }
  toPathString() {
    let queryString = '';
    this.toQueryMap().forEach((value: string, key: string) => {
      if(key!=="fields")
      queryString = queryString.concat(`/${value}`);
    });
    this.toQueryMap().forEach((value: string, key: string) => {
      if(key==="fields")
      queryString = queryString.concat(`?${key}=${value}`);
    });
    return queryString;
  }
}
