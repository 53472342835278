import { DatePipe, registerLocaleData } from '@angular/common';
import { AfterContentChecked,  Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {TranslateService, TranslationChangeEvent} from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {  Observable} from 'rxjs';
import { ConfigService } from 'src/app/core/config.service';
import { IDropdown } from 'src/app/core/model/dropdown.interface';
import { ILanguage } from 'src/app/core/model/language.interface';
import { ProgramDataService } from 'src/app/core/services/api/program.service';
import { CountryService } from 'src/app/core/services/country.services';
import { GenreService } from 'src/app/core/services/gerne.services';
import { LanguageService } from 'src/app/core/services/language.service';
import { PriceService } from 'src/app/core/services/price.service';
import { QueryOptions } from 'src/app/core/services/query.options';
import { SignalrService } from 'src/app/core/services/signalR/SignalrService';
import { YearService } from 'src/app/core/services/year.service';
import { SortData } from 'src/app/helper/sort-data-by-name';
import localeNl from '@angular/common/locales/nl';
import localeDE from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import { IPrograminternal, PrograminternalData } from 'src/app/core/model/programinternal.interface';
import { groupBy} from 'lodash-es';
import { ProgramDetailsDialogComponent } from './dialog/program-details-dialog.component';
import { EditionService } from 'src/app/core/services/api/edition.service';
import { IProgram } from 'src/app/core/model/program.interface';
import { IEdition } from 'src/app/core/model/edition.interface';
import { IProgramGroup } from 'src/app/core/model/programgroup.interface';
import { DialogService } from 'primeng/dynamicdialog';
import { Filter } from 'src/app/helper/filter';
import { animate, query,  state, style, transition, trigger } from '@angular/animations';
import { VisibilityState } from 'src/app/helper/enum/VisibilityState.enum';
import { Direction } from 'src/app/helper/enum/direction.enum';
import { DestroySubscribers } from 'src/app/core/destroysubscribers';
import { LoaderService } from 'src/app/core/loader/loader.service';

@Component({
  selector: 'kruh-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.css'],
  providers: [DatePipe],
  animations: [
  trigger('toggle_p', [
      state(
        VisibilityState.Hidden,
        style({  height: '0px', transform: 'translateY(-100%)',opacity: 0 })
      ),
      state(
        VisibilityState.Visible,
        style({  height: '61px',transform: 'translateY(0)',opacity: 1 })
      ),
      transition('* => *', [
        query(':self', [
          animate('250ms ease')
        ])
      ]),
      //, transition('hidden => visible', [
      //   query(':self', [
      //     animate('250ms ease'),
      //     stagger(5250,
      //       animate('250ms ease', style({ opacity: 1 }))
      //     )
      //   ])
      // ])
    ])
  ]
})
@DestroySubscribers()
export class ProgramComponent implements OnInit,  AfterContentChecked {
  isVisible: boolean=true;
  language: ILanguage[];
  years: IDropdown[]=[];
  genres: any[]=[];
  countries: any[]=[];
  price: any[];
  PHSELECTYEAR: any;
  TRAILER: any;
  PRICE: any;
  AWARDS: any;
  TAGLINE: any;
  STARS: any;
  GENRE: any;
  LMOVIE: any;
  LYEAR: any;
  YEAR: any;
  DATEEVENT: any;
  LENGTH: any;
  COUNTRY: any;
  REGIE: any;
  SHARE: any;
  LTITLE: any;
  NOPROGRAM: any;
  NOPROGRAMDETAILS: any;
  arraydata: IProgram[]=[];
  arraygroupdata: IProgramGroup[]=[];
  groupsList: IDropdown[]=[];
  year: number=0;
  imagesArray: IDropdown[]=[];
  regex: RegExp;
  trailerUrl!: string;
  title: any;
  length: number=0;
  synopsis!: string;
  regie!: string;
  countryNames: any;
  imdbStars!: string;
  imdbTagline!: string;
  form: FormGroup;
  pricedata: any;
  youtubeid: any;
  dateevent!: Date;
  ytimg!: string;
  yeardatevent!: number;
  gid: number=0;
  imdbAwards!: string;
  progaminteranlarray: IPrograminternal[]=[];
  states:any[]=[];
  test: any;
  groupedSeriesNames: any[]=[];
  groupedSeries: any;
  nr: number=0;
  PROGRAMTITLE: string='Programm';
  scrolled: boolean=false;
  results: string[]=[];
  PHSEARCH: any;
  isSearchResult: boolean=false;
  dateNow!: Date;
  scroll$!: Observable<any>;
  showSpecialPrograms: boolean=false;
  lang!: string;
  BARN:string='Scheunenkino';
  upcomming:string='';
  NEXT:string='';

  constructor(
    private datePipe: DatePipe,
    public _dialogService: DialogService,
    public _signalrService: SignalrService,
    private _dataservices: ProgramDataService,
    private _editionservices: EditionService,
    private _translate: TranslateService,
    public _languageService: LanguageService,
    public _yearService: YearService,
    public _genreService: GenreService,
    public _countryService: CountryService,
    private _config:ConfigService,
    public _priceService: PriceService,
    private _loaderService: LoaderService
  ) {
    registerLocaleData(localeDE, 'de');
    registerLocaleData(localeNl,'nl');
    registerLocaleData(localeFr,'fr');
    this.regex =  new RegExp(this._config.getRegex('youtubeUrlfilter'),"i");
    this.language= this._languageService.getLanguage();

    this.getCountriesAndGenres();
    this.price = SortData.By(this._priceService.getPrice(),"name");

    this.form = new FormGroup({
      yearName: new FormControl(null),
      titleName: new FormControl(null)
    });
    this.form.get('yearName')?.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(val => {
        this.showSpecialPrograms=false;
        if( this.yeardatevent!=+val.name && !this.isSearchResult )
       {
      
         this.GetContent(+val.name);
         if(this.titleName?.value!=null && this.titleName?.value!='')
         this.form.patchValue({
          titleName:''
         });
        }
      else
      {
        this.isSearchResult=false;
      }
    });
    this.GetLanguageData();
    this._translate.onLangChange.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe((event: TranslationChangeEvent) => {


        this.getCountriesAndGenres();
        this.GetLanguageData();
        this.setYear();

        if(this.lang!=undefined && this.lang!=this._translate.currentLang)
          {
            if(this.yearName!=null)
            {
             const y:string= this.yearName.value==null?this.year.toString():this.yearName.value.name;
             this.GetContent(+y);
            }
            else{
              this.GetContent(this.year);
            }
          }
          if(this._translate.currentLang!=='de')
          this.title =`${this.PROGRAMTITLE} ${this.yeardatevent} #${this.nr}`;
        else
        this.title =`${this.PROGRAMTITLE} ${this.yeardatevent} ${this.nr}.`;
          this.lang = this._translate.currentLang;

    });
  }
  private getCountriesAndGenres() {
    this.genres = SortData.By(this._genreService.getGenre(this._translate.currentLang), "name");
    this.countries = SortData.By(this._countryService.getCountries(this._translate.currentLang), "name");
  }

  ngAfterContentChecked(): void {
    if(!this.scrolled)
    this.scrollToUpcomingEvent()
  }

  scrollChanged(event:Direction)
  {
      this.isVisible =event==Direction.Up?true:false;
  }

  get toggle(): VisibilityState {
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }

  ngOnInit(): void {
    this._loaderService.show();
    this.setYear();
    this.years = this._yearService.getProgrammYears().filter(l=>{return +l.name<=this.year});
    // this.titleName?.valueChanges.pipe(
    //   debounceTime(900),
    //   distinctUntilChanged())
    //   .subscribe(val => {
    //     let qo:QueryOptions= new QueryOptions;
    //     qo.type="search";
    //     qo.search=encodeURIComponent(val);
    //     qo.lcid=this._translate.currentLang;
    //     this._dataservices.listByPath(qo).subscribe(
    //       (result:any) =>{
    //         this.results =Filter.ByValueForAC(result,val);
    //       });

    // });

     this.GetContent(this.year);
  }
  private setYear() {
    this.dateNow = new Date();
    this.year = this.dateNow.getFullYear();
  }

  get yearName() {
    return this.form.get('yearName');
  }
  get titleName() {
    return this.form.get('titleName');
  }
  // T.b.v. autocomplete input
  search(event:any) {
    let query = event.query;

    let qo:QueryOptions= new QueryOptions;
    qo.type="search";
    qo.search=encodeURIComponent(query);
    qo.lcid=this._translate.currentLang;
    this._dataservices.listByPath(qo).subscribe(
      (result:any) =>{
        this.results =Filter.ByValueForACprogram(result,query,this._translate.currentLang);
      });
  }
    // Autocomplete gekozen item
  ACSelect(event:any) {
    const id:number = +event.split('#')[1];
    let o:QueryOptions= new QueryOptions;
    o.id = id;
    //o.fields='id,programgroup_id,title,dateevent,length,regie,trailer,name,stars,tagline,awards,stars,year';
    this._dataservices.listByPath(o).subscribe( (result:IProgram[]) =>{

      this.progaminteranlarray = [];
      this.groupedSeriesNames = [];
      this.groupedSeries = [];
      if(result!=null && result.length>0)
      {
        result = SortData.By(result,"eventdate");
        this.procesData(result);

        this.groupedSeries = groupBy(this.progaminteranlarray, 'dateeventdate')
        this.groupedSeriesNames = Object.keys(this.groupedSeries);


        const y = this.GetYearAndScroll(result);
        this.isSearchResult=true;
        this.form.patchValue({
          yearName:y
          });

          let oe:QueryOptions= new QueryOptions;
          oe.lcid = this._translate.currentLang;
          oe.year =this.yeardatevent;
          oe.fields ='title,nr';
          this._editionservices.list(oe).subscribe((result:IEdition[]) =>{
            if(result!=null)
            {
              this.title= result[0].title;
              this.nr = result[0].nr;
            
              if(this._translate.currentLang!=='de')
                {
                this.title =`${this.PROGRAMTITLE} ${this.yeardatevent} #${this.nr}`;

                }
              else
              {
              this.title =`${this.PROGRAMTITLE} ${this.yeardatevent} ${this.nr}.`;
              }
            }
          });
      }
    });
  }

  ACclear()
  {
    this.setYear();
    this.GetContent(this.year);
  }
  private GetContent(y:number) {
    this.title =`${this.PROGRAMTITLE} ${y}`;
    let o:QueryOptions= new QueryOptions;
    o.lcid = this._translate.currentLang;
    o.year =y;
    let o1:QueryOptions= new QueryOptions;
    o1.lcid = this._translate.currentLang;
    o1.id= 0;
    this._dataservices.list(o).subscribe( (result:IProgram[]) =>{

        this.progaminteranlarray = [];
        this.groupedSeriesNames = [];
        this.groupedSeries = [];
        if(result!=null && result.length>0)
        {
          this._loaderService.show();
          result = SortData.By(result,"eventdate");

          this.procesData(result);

          this.groupedSeries  = groupBy(this.progaminteranlarray, 'dateeventdate')
          this.groupedSeriesNames = Object.keys(this.groupedSeries);
          const year = this.GetYearAndScroll(result);
          if(this.yearName?.value==null)
          {
            this.form.patchValue({
              yearName:year
             });
          }
          else if(year.name!== this.yearName.value.name)
          {
            // this._messageService.clear()
            // this._messageService.add({key :'center',severity:'warn', summary:this.NOPROGRAM, detail:this.NOPROGRAMDETAILS});
            this.form.patchValue({
              yearName:year
           });

          }

          o.fields ='title,nr,eventend_n,nextevent_n';
          this._editionservices.list(o).subscribe( (result:IEdition[]) =>{
            if(result!=null)
            {
              this.nr = result[0].nr;
             const t:string = result[0].title;
              // this.title= result[0].title;
              if(this.PROGRAMTITLE!=undefined)
                {
                  const epoch:number = result[0].nextevent_n*1000;
                const nextdate =  new Date( epoch);
                const nextdateend:Date =new Date(epoch);
                nextdateend.setDate(nextdateend.getDate()+2);
                 
                  if(this._translate.currentLang!=='de')
                    this.title =`${this.PROGRAMTITLE} ${this.yeardatevent} #${this.nr}`;
                  else
                  this.title =` ${this.nr}. ${this.PROGRAMTITLE} ${this.yeardatevent}`;
                  if((result[0].eventend_n*1000)<this.dateNow.getTime() && this.upcomming==='')
                  //  this.upcomming = `${this.NEXT}  ${nextdate.toLocaleDateString(this._translate.currentLang,{ weekday: 'long' })}, ${nextdate.getDate()} - ${nextdateend.getDate()}  ${nextdate.toLocaleDateString(this._translate.currentLang,{ month: 'long' })} ${this.yeardatevent+1}`;
                    this.upcomming = this.formatDateRange(nextdate,nextdateend);
                }

              this._loaderService.hide();
            }
          });
        }
     }
    );
  }

  private GetYearAndScroll(result: IProgram[]) {
    const datevent = new Date(result[0].eventdate*1000);
    const lastevent = new Date(result[result.length-1].eventdate*1000);
    this.yeardatevent = datevent.getFullYear();
    const y = this.years.filter(l => { return +l.name == this.yeardatevent; })[0];

    if (+y.name == this.year &&  this.dateNow < lastevent) {
      this.scrolled = false;
    }
    else {
      this.scrolled = true;
    }
    return y;
  }

  private procesData(result: IProgram[]) {

    result.forEach(element => {
      const p = this.price.filter(l => { return l.code == element.price_id.toString(); })[0];
      const gn = element.genre_ids == undefined ? [] : this.genres.filter((gx) => { return element.genre_ids.split(',').map(Number).includes(+gx.code); });
      const co = element.land == undefined ? [] : this.countries.filter((c) => { return element.land.split(',').map(Number).includes(+c.code); });
      const i = element.image == undefined ? [] : element.image.split(',').map(String);


      const pi = new PrograminternalData();
      pi.imagesArray = [];
      i.forEach(element => {
        let prefix: string = element.startsWith('/upload') ? 'https://filmfestkruh.de' : 'https://filmfestkruh.blob.core.windows.net/imgs/';
        if (element.startsWith('http')) {
          prefix = '';
        }
        let dp: IDropdown = { name: element.replace(/imgs/gi, 'thumbnails'), code: `${prefix}${element.replace(/thumbnails/gi, 'imgs')}` };
        pi.imagesArray.push(dp);
      });
      const ep:number = element.eventdate * 1000;
      pi.id = element.id;
      pi.gid = element.programgroup_id;
      pi.programtypecolor = element.programgroup_id === 6 ? '#ffdebf' : '#9dffab';
      pi.programtypecolor = element.programgroup_id === 7 ? '#ff8d8d' : pi.programtypecolor;
      // pi.programtypecolor = element.programgroup_id === 8 ? '##ffdebf' : pi.programtypecolor;
      pi.title = element.title;
      pi.dateevent = new Date(ep);
      pi.dateeventdate = this.datePipe.transform(ep, 'EEEE, d. MMMM', undefined, this._translate.currentLang);
      pi.epoch = ep;
      pi.length = element.length;
      pi.synopsis = element.synopsis;
      pi.regie = element.regie;
      pi.genre = gn;
      pi.countryNames = co;
      pi.youtubeid = element.trailer;
      pi.trailerUrl = `https://www.youtube.com/watch?v=${element.trailer}`,
        pi.ytimg = `url("https://i.ytimg.com/vi/${element.trailer}/hqdefault.jpg")`;
      if (p !== undefined)
        pi.pricedata = p.name;
      pi.stars = element.stars;
      pi.tagline = element.tagline;
      pi.awards = element.awards;
      pi.stars = element.stars;
      pi.year = element.year;
      pi.contentrating = element.contentrating;
      this.progaminteranlarray.push(pi);
    });
  }

  public showdetail(event:any)
  {
    const dialogRef = this._dialogService.open(ProgramDetailsDialogComponent, {
      width: 'calc(98%)',
      height:'92%',
      header: this.progaminteranlarray.filter(f=> f.id==+event)[0].title,
      data: {
        id:  event,
        event:this.progaminteranlarray
      },
      closable:true,
      closeOnEscape:true
    });
  }
  private scrollToUpcomingEvent(){
    if(this.progaminteranlarray.length==0)
      return;
    const nd:number =new Date().getTime();
    const closestevent:any = this.findClosestForIn(this.progaminteranlarray, nd);

    if(closestevent==null)
      return;
   // const closestevent:any = this.findClosestForIn(this.progaminteranlarray, 1659258000000);
     const list:any = document.getElementById("programlist");
     // const targetLi:any= document.getElementById("Sunday, 31. July");
     const targetLi:any= document.getElementById(closestevent.epoch);
     if(targetLi)
     {
       list.scrollTop  = (targetLi.offsetTop - 150);
       this.scrolled =true;
     }
   }
   findClosestForIn(items:any, target:number) {
     let closestVal = Infinity;
     let closestObj = null;

     for (let key in items) {
         let obj = items[key];
         let curDiff = Math.abs(obj.epoch - target);

         if (curDiff < closestVal) {
           closestVal = curDiff;
           closestObj = obj;
         }
     }
     return closestObj;
   }
   formatDateRange(startDate: Date, endDate: Date): string {
    //const optionsstart: Intl.DateTimeFormatOptions = {weekday: 'long', month: 'long', day: 'numeric' };
    const optionsstart: Intl.DateTimeFormatOptions = startDate.getMonth()===endDate.getMonth() ?{weekday: 'long', day: 'numeric' }: { weekday: 'long',month: 'long', day: 'numeric' };
    const options: Intl.DateTimeFormatOptions = startDate.getMonth()===endDate.getMonth() ?{ month: 'long', day: 'numeric' }: { month: 'long', day: 'numeric' };
    const start = startDate.toLocaleDateString(this._translate.currentLang, optionsstart);
    const end = endDate.toLocaleDateString(this._translate.currentLang, options);
    return `${start} - ${end} ${endDate.getFullYear()} ${this.daysToSpecificDate(startDate)}`;
   }
   daysToSpecificDate(targetDate:Date):string {
      const today = new Date();
      const target = targetDate;
      //const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
      const diffDays = Math.round((target.getTime() - today.getTime()) / 86400000);
    switch (this._translate.currentLang) {
      case 'de':
        return `- Noch ${diffDays} Tage`;
      case 'nl':
        return `- Over ${diffDays} dagen`;
      case 'fr':
        return `- Il reste ${diffDays} jours`;
        case 'en':
          return `- ${diffDays} days to go`;
          default:
            return `- Noch ${diffDays} Tage`;
    }
    }

   private setvalues(app: any,program:any) {
    this.LTITLE=     app['LTITLE'];
    this.SHARE =     app['SHARE'];
    this.LYEAR =     app['YEAR'];
    this.PHSELECTYEAR = app['PHSELECTYEAR'];
    this.REGIE =     program['REGIE'];
    this.COUNTRY =   program['COUNTRY'];
    this.LENGTH  =   program['LENGTH'];
    this.DATEEVENT = program['DATEEVENT'];
    this.YEAR =      program['YEAR'];
    this.LMOVIE =    program['LMOVIE'];
    this.GENRE =     program['GENRE'];
    this.STARS =     program['STARS'];
    this.TAGLINE =   program['TAGLINE'];
    this.AWARDS =    program['AWARDS'];
    this.PRICE =     program['PRICE'];
    this.TRAILER =   program['TRAILER'];
    this.NOPROGRAM = program['NOPROGRAM'];
    this.PHSEARCH =  program['PHSEARCH'];
    this.PROGRAMTITLE = program['TITLE'];
    this.NOPROGRAMDETAILS = program['NOPROGRAMDETAILS'];
    this.BARN = program['BARN'];
    this.NEXT = program['NEXT'];
  }

  private GetLanguageData() {
    const data = this._translate.instant('APP');
    const program = this._translate.instant('PROGRAM');
    if(typeof program !== 'string')
    this.setvalues(data,program);
  }
}
