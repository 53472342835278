import { Directive, Output, EventEmitter,  ElementRef, OnDestroy 
} from '@angular/core';
import {  distinctUntilChanged, map,throttleTime,share } from 'rxjs/operators';
import {  fromEvent} from 'rxjs';
import { Direction } from '../helper/enum/direction.enum';



@Directive({
  selector: '[scroll]'
})
export class ScrollDirective implements OnDestroy {
  @Output() scrollPosition: EventEmitter<Direction> = new EventEmitter<Direction>
  ();

  private scrollEvent$;

  constructor(private el: ElementRef) {
    this.scrollEvent$ = fromEvent(this.el.nativeElement, 
    'scroll')
    .pipe(
          throttleTime(100),
          map(() => this.el.nativeElement.scrollTop),
          //pairwise(),
         //tap(([y1, y2]) => console.log(`${y1} --- ${y2}`)),
          map((y1): Direction => (( y1<300) ? Direction.Up : Direction.Down)),
          distinctUntilChanged(),
          share()
        )
    .subscribe((e: Direction) => {
      this.scrollPosition.emit(e);
    });
  }

  ngOnDestroy() {
    this.scrollEvent$.unsubscribe();
  }
}