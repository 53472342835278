import { Injectable } from "@angular/core";
import { TranslateService, TranslationChangeEvent } from "@ngx-translate/core";
import { Observable, Subject, debounceTime, delay, of } from "rxjs";
import { DomHandler } from "primeng/dom";

@Injectable({
    providedIn: 'root'
  })
export class TextSelectionService {
  public  selectionEndTimeout:boolean = false;
  delaySubject: Subject<boolean> = new Subject();
  delay$:Observable<boolean>;
  t!: string;
  read: any;
  reading: any;

    public getNativeWindow(): any {
        return window;
    }
    private getNativeDocument(): any {
        return document;
    }
    constructor(private _translateService:TranslateService) {
      this._translateService.onLangChange.subscribe((event:TranslationChangeEvent) => {
        const speech = event.translations['SPEECH'];
        this.setvalues(speech);
      });
      this.GetLanguageData()
        this.delay$ = this.delaySubject.asObservable();
        this.delay$.subscribe((data:boolean)=>{
            if(data)
            {
              this.getSelectionText();
             this.selectionEndTimeout=false;
            }
          });
        this.getNativeDocument().onselectionchange = () => {
          of('change').pipe(debounceTime(500)).subscribe(()=>{
            this.userSelectionChanged();
          });
        };
    }

  public newMethod(button:any) {
    var msg = new SpeechSynthesisUtterance(this.t);
    msg.addEventListener("end", () => {
      button.remove();
      this.getNativeWindow().getSelection().removeAllRanges();
      msg.removeEventListener("end", () => {});
      msg.onerror = (event:any) => { console.log("An error has occurred with the speech synthesis: " + event.error);};
    });
    msg.lang = this._translateService.currentLang;
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(msg);
  }

 public userSelectionChanged() :void {
        // wait 500 ms after the last selection change event
        if (this.selectionEndTimeout) {
             this.selectionEndTimeout  = false;
        }
        of('timeout').pipe(delay(500)).subscribe(()=>{
          this.delaySubject.next(true);
          this.selectionEndTimeout = true;
        });
  }
  get buttonSpeech() {
  
    return this.getNativeDocument().getElementById('speech');
}

  public getSelectionText() :void {
    if(this.getNativeWindow().getSelection().type!="Range")
      return;
    if (this.getNativeWindow().getSelection()) {
      this.t = this.getNativeWindow().getSelection().toString();
      if(this.buttonSpeech!==null)
        DomHandler.removeElement(this.buttonSpeech)
      

    } else if (this.getNativeDocument().selection && this.getNativeDocument().selection.type != "Control") {
      this.t = this.getNativeDocument().selection.createRange().text;
    }
    if(this.t.length>0)
    {
      let width:number=0;
      let height:number=0;
      let top:number=0;
      let x:number=0;
      const sel =this.getNativeWindow().getSelection();
      const size = window.getComputedStyle(sel.anchorNode.parentElement, null).getPropertyValue('font-size');
      if (sel.rangeCount) {
       const range = sel.getRangeAt(0).cloneRange();
       if (range.getClientRects) {
           const rect = range.getClientRects();
           x = (rect[rect.length-1].left+(rect[rect.length-1].width/2)-80)
           width = ((rect.right - rect.left)/2)+80;
           if(width<158)
           {
            width = 0;
           }
           height = +size.replace(/px/g, "")+7;
           top = rect[rect.length-1].top+rect[rect.length-1].height+7;
       }
     }
      const button= document.createElement("button");
      button.id='speech';
      button.innerHTML = `<span class="dre-button__touch-area">
                            <span class="dre-button__main dre-button__main--tertiary dre-button__main--tertiary-pressed dre-button__main--medium">
                              <span class=" dre-icon-wrapper dre-icon-wrapper--xxs-x-small" style="color: inherit;">
                                <span class="dre-icon-wrapper__wrapper">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M7 13.4048c2.6923 0 5.1257 1.1002 6.8025 2.9273l.1975.2227V20H0v-3.4452c1.68-1.89 4.2-3.15 7-3.15zm0 2c-1.8309 0-3.5792.6942-4.9083 1.8807L2 17.369V18h10v-.648l-.0968-.0925-.2202-.1927c-1.1957-1.0023-2.7284-1.5936-4.3879-1.6564zM16.5552 0C18.6834 2.1659 20 5.1279 20 8.3939c0 2.5676-.8225 4.9393-2.2052 6.8914l-.2116.2896-1.4278-1.425c1.1553-1.629 1.8406-3.613 1.8406-5.756 0-2.6019-1.003-4.9714-2.6405-6.7512l-.2171-.2288 1.4168-1.414zm-2.8326 2.8269c1.4018 1.442 2.2695 3.404 2.2695 5.567 0 1.4774-.4095 2.8573-1.113 4.0453l-.1675.2707-1.456-1.453c.466-.852.7325-1.826.7325-2.863 0-1.5172-.5689-2.9015-1.503-3.9587l-.1793-.1943 1.4168-1.414zM7 5c1.933 0 3.5 1.567 3.5 3.5S8.933 12 7 12s-3.5-1.567-3.5-3.5S5.067 5 7 5zm0 2c-.8284 0-1.5.6716-1.5 1.5S6.1716 10 7 10s1.5-.6716 1.5-1.5S7.8284 7 7 7z" transform="translate(2 2)"></path>
                                  </svg>
                                </span>
                                </span>
                                <span class="dre-button__text dre-button__text--medium">
                                  <span><span>${this.read}</span>
                                </span>
                              </span>
                            </span>
                          </span>`;
      button.className='tooltip';
      if(x!=0)
      {
      button.style.left = `${x}px`;
      button.style.top = `${top}px`;
      button.style.transform = `translate3d(0px, ${size}px, 0px)`;
      }
      else
      {
        button.style.transform = `translate3d(-${width}px, ${height}px, 0px)`;
      }
      button.onclick = (event:any) => {
        event.stopPropagation();
        this.newMethod(button);
        button.innerHTML = `<span class="dre-button__touch-area"><span class="dre-button__main dre-button__main--tertiary dre-button__main--tertiary-pressed dre-button__main--medium"><span class=" dre-icon-wrapper dre-icon-wrapper--xxs-x-small" style="color: inherit;"><span class="dre-icon-wrapper__wrapper">
        <span class="dre-activity-animation-icon" style="color: inherit;"><span class="dre-activity-animation-icon__bar dre-activity-animation-icon__bar--first"></span><span class="dre-activity-animation-icon__bar dre-activity-animation-icon__bar--second"></span><span class="dre-activity-animation-icon__bar dre-activity-animation-icon__bar--third"></span></span>
        </span></span><span class="dre-button__text dre-button__text--medium"><span><span>${this.reading}</span></span></span></span></span>`;

      };
      this.getNativeWindow().getSelection().anchorNode.parentElement.style.position = 'relative';
      this.getNativeWindow().getSelection().anchorNode.parentElement.appendChild(button);
    }
  }

  private setvalues(speech: any) {
    this.read= speech['READ'];
    this.reading= speech['READING'];
  }
  private GetLanguageData() {
      const speech = this._translateService.instant('SPEECH');
      this.setvalues(speech);
  }
}