import {  Component, OnInit } from '@angular/core';
import { QueryOptions } from 'src/app/core/services/query.options';
import {TranslateService, TranslationChangeEvent} from '@ngx-translate/core';
import { DestroySubscribers } from 'src/app/core/destroysubscribers';
import { ContentDataService } from 'src/app/core/services/api/editorial.service';
import { YearService } from 'src/app/core/services/year.service';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { IDropdown } from 'src/app/core/model/dropdown.interface';
import { IEdition } from 'src/app/core/model/edition.interface';
import { EditionService } from 'src/app/core/services/api/edition.service';
import { Filter } from 'src/app/helper/filter';
import { animate, query, state, style, transition, trigger } from '@angular/animations';
import {  distinctUntilChanged} from 'rxjs/operators';
import { VisibilityState } from 'src/app/helper/enum/VisibilityState.enum';
import { Direction } from 'src/app/helper/enum/direction.enum';
import { IContent } from 'src/app/core/model/content.interface';

@Component({
  selector: 'kruh-editorial',
  templateUrl: './editorial.component.html',
  styleUrls: ['./editorial.component.css'],
  animations: [
    trigger('toggle', [
      state(
        VisibilityState.Hidden,
        style({  height: '0px', transform: 'translateY(-100%)',opacity: 0 })
      ),
      state(
        VisibilityState.Visible,
        style({  height: '61px',transform: 'translateY(0)',opacity: 1 })
      ),
      transition('* => *', [
        query(':self', [
          animate('250ms ease')
        ])
      ])
      ])
    ]
})
@DestroySubscribers()
export class EditorialComponent implements OnInit {
  content:string='';
  arrayData: IContent[]=[];
  title: string='';
  PHSELECTYEAR: any;
  LYEAR: any;
  form: FormGroup;
  years:IDropdown[]=[];
  year: number=0;
  nr!: number;
  PHSEARCH: any;
  isSearchResult: boolean=false;
  results: string[]=[];
  query: string='';
  isVisible: boolean=true;
  constructor(
    private _dataservices:ContentDataService,
    private _editionservices: EditionService,
    private  _translate: TranslateService,
    public _yearService: YearService
  ) {

    this.form = new FormGroup({
      yearName: new FormControl(null),
      titleName: new FormControl(null)
    });
    this.form.get('yearName')?.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(val => {
        if( this.year!=+val.name && !this.isSearchResult )
        {
          this.year=+val.name;
          this.GetContent(this.year);
          if(this.titleName?.value!=null && this.titleName?.value!='')
          this.form.patchValue({
           titleName:''
          });
        }
        else{
          this.isSearchResult=false;
        }
    });
    this._translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
      const app =  event.translations['APP'];
      const editorial = event.translations['EDITORIAL'];
      this.setvalues(app,editorial);
      this.GetContent(this.year);
    });
  }

  ngOnInit() {
    const dateNow:Date= new Date();
    this.year =dateNow.getFullYear();

    this.years = this._yearService.getEditorialYears().filter(l=>{return +l.name<=this.year });
    this.GetLanguageData()
    this.GetContent(this.year);
  }

  get yearName() {
    return this.form.get('yearName');
  }
  get titleName() {
    return this.form.get('titleName');
  }

  scrollChanged(event:Direction)
  {
      this.isVisible =event==Direction.Up?true:false;
  }
  get toggle(): VisibilityState {
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }

  private GetContent(y:number) {
    let o1:QueryOptions= new QueryOptions;
    o1.fields ='title,nr';
    o1.lcid = this._translate.currentLang;
    o1.year =y;
    this._editionservices.list(o1).subscribe( (result:IEdition[]) =>{
      if(result!=null)
      {
        this.title= result[0].title;
        this.nr = result[0].nr;
        this.getEditorial(y);
      }
    });
  }

  private getEditorial(y: number) {
    let o: QueryOptions = new QueryOptions;
    o.type = '1';
    o.year = y;
    o.lcid = this._translate.currentLang;
    this._dataservices.list(o).subscribe((result: IContent[]) => {
      if (result != null && result.length > 0) {
        this.arrayData = result;
        this.content = this.arrayData[0].description;
        if(this._translate.currentLang!=='de')
          {
            this.title = `#${this.nr} ${result[0].title}`;
          }
          else{
            this.title = `${this.nr}. ${result[0].title}`;
          }
       
        const y = this.years.filter(l => { return l.name == result[0].year.toString(); })[0];
          this.form.patchValue({
            yearName: y
          });
      }
    });
  }

  // T.b.v. autocomplete input
  search(event:any) {
    let query = event.query;
    this.query= event.query;
    let qo:QueryOptions= new QueryOptions;
    qo.type="search";
    qo.search=encodeURIComponent(query);
    qo.lcid=this._translate.currentLang;
    this._dataservices.listByPath(qo).subscribe(
      (result:any) =>{
        this.results =Filter.ByValueForACcontent(result,query,this._translate.currentLang);
      });
  }
      // Autocomplete gekozen item
      ACSelect(event:any) {
        const id:number = +event.split('#')[1];
        let o:QueryOptions= new QueryOptions;
        o.id = id;
        this._dataservices.list(o).subscribe( (result:IContent[]) =>{

          if(result!=null && result.length>0)
          {
            this.arrayData = result;
            const regex = new RegExp(this.query, "gi");
            this.content = this.arrayData[0].description.replaceAll(regex,`<span class="yellow">$&</span>`);

            this.year =result[0].year;
             const y =this.years.filter(l=>{return +l.name==this.year})[0];
            this.isSearchResult=true;
            this.form.patchValue({
              yearName:y
              });

              let oe:QueryOptions= new QueryOptions;
              oe.lcid = this._translate.currentLang;
              oe.year =this.year;
              oe.fields ='title,nr';
              this._editionservices.list(oe).subscribe((result:IEdition[]) =>{
                if(result!=null)
                {
                  this.title= result[0].title;
                  this.nr = result[0].nr;
                }
              });
          }
        });
      }

  ACclear()
  {
    this.setYear();
    const y = this.years.filter(l => { return l.name == this.year.toString(); })[0];
    this.form.patchValue({
        yearName: y
      });
    this.GetContent(this.year);
  }
  private setYear() {
    const dateNow: Date = new Date();
    this.year = dateNow.getFullYear();
  }

  private setvalues(app: any, editorial:any) {
    this.PHSELECTYEAR = app['PHSELECTYEAR'];
    this.LYEAR = app['YEAR'];
    this.PHSEARCH = editorial['PHSEARCH'];
  }

  private GetLanguageData() {
    const data = this._translate.instant('APP');
    const editorial = this._translate.instant('EDITORIAL');
    if(typeof data !== 'string' && typeof editorial !== 'string')
    this.setvalues(data,editorial);
  }
}
