<mat-tab-group  (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="selectedIndex">
  <mat-tab [label]="nameprogram">
    <ng-template #program></ng-template>
  </mat-tab>
  <mat-tab [label]="nameeditorial">
    <ng-template #editorial></ng-template>
  </mat-tab>
  <mat-tab [label]="nameinfo">
    <ng-template #info></ng-template>
  </mat-tab>
  <mat-tab [label]="nameanreise">
    <ng-template #anreise></ng-template>
  </mat-tab>
  <mat-tab [label]="namefotos" class="photos">
    <ng-template #photos></ng-template>
  </mat-tab>
  <mat-tab [label]="namevideos">
    <ng-template #videos></ng-template>
  </mat-tab>
  <mat-tab [label]="namepanorama">
    <ng-template #panorama></ng-template>
  </mat-tab>
  <mat-tab [label]="nameballmertshofen">
    <ng-template #ballmertshofen></ng-template>
  </mat-tab>
  <mat-tab [label]="nameshop">
    <ng-template #shop></ng-template>
  </mat-tab>
  <mat-tab [label]="namecontact">
    <ng-template #contact></ng-template>
  </mat-tab>
  <mat-tab [label]="namelinks">
    <ng-template #link></ng-template>
  </mat-tab>
  <mat-tab [label]="namedatenschutz">
    <ng-template #datenschutz></ng-template>
  </mat-tab>
</mat-tab-group>
