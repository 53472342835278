
export interface IPrograminternal  {
    id:number;
    title:string;
    regie:string;
    imagesArray:any;
    synopsis:string;
    length:number;
    year:number;
    countryNames:any[];
    youtubeid:string;
    trailerUrl:string;
    ytimg:string;
    genre:any[];
    dateevent:Date;
    dateeventdate: string|null;
    gid:number;
    pricedata:any;
    stars?:string;
    tagline?:string;
    awards?:string;
    programtypecolor:string;
    contentrating?:string;
   }

export  class PrograminternalData implements IPrograminternal {
    id!:number;
    event_id!:number;
    title!: string;
    regie!: string;
    imagesArray!: any;
    synopsis!: string;
    length!: number;
    year!: number;
    countryNames: any[]=[];
    youtubeid!: string;
    trailerUrl!: string;
    ytimg!: string;
    genre: any[]=[];
    dateevent!: Date;
    dateeventdate!: string|null;
    gid!: number;
    pricedata: any;
    stars?: string;
    tagline?: string;
    awards?: string;
    programtypecolor!:string;
    epoch!:number;
    contentrating?:string;
}

