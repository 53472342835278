import { NgModuleFactory, Type } from '@angular/core';

export const lazyWidgets: { path: string, loadChildren: () => Promise<NgModuleFactory<any> | Type<any>> }[] = [
  {
    path: 'home',
    loadChildren: () => import('../components/info/info.modules').then(m => m.InfoModule)
  },
  {
    path: 'anreise',
    loadChildren: () => import('../components/anreise/anreise.module').then(m => m.AnreiseModule)
  }
  ,{
    path: 'shop',
    loadChildren: () => import('../components/shop/shop.module').then(m => m.ShopModule)
  }
  ,{
    path: 'ballmertshofen',
    loadChildren: () => import('../components/info/info.modules').then(m => m.InfoModule)
  }
  ,{
    path: 'datenschutz',
    loadChildren: () => import('../components/info/info.modules').then(m => m.InfoModule)
  }
  ,{
    path: 'contact',
    loadChildren: () => import('../components/info/info.modules').then(m => m.InfoModule)
  }
  ,{
    path: 'editorial',
    loadChildren: () => import('../components/info/info.modules').then(m => m.InfoModule)
  }
  ,{
    path: 'program',
    loadChildren: () => import('../components/program/program.module').then(m => m.ProgramModule)
  }
  ,{
    path: 'video',
    loadChildren: () => import('../components/videos/videos.module').then(m => m.VideosModule)
  }
  ,{
    path: 'period',
    loadChildren: () => import('../management/management.module').then(m => m.ManagmentModule)
  }
  ,{
    path: 'photos',
    loadChildren: () => import('../components/fotos/foto.module').then(m => m.FotoModule)
  }
  ,{
    path: 'shop',
    loadChildren: () => import('../components/shop/shop.module').then(m => m.ShopModule)
  }
  ,{
    path: 'panorama',
    loadChildren: () => import('../components/panorama/panorama.module').then(m => m.PanoramaModule)
  }
  
];

export function lazyArrayToObj() {
  const result:any = {};
  for (const w of lazyWidgets) {
    result[w.path] = w.loadChildren;
  }
  return result;
}